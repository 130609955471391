import React, { useEffect, useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { PageWrapper, H1WithBack, Flex, ContentWrapper, Separator } from 'styling/GlobalStyles'
import { TaskOperationTab } from './TaskTOperationTab'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  addLogic,
  clearTaskTemplate,
  editTaskTemplate,
  getSingleTaskTemplate,
} from 'features/taskTemplates/taskTemplatesSlice'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { TaskComponentsTab } from './TaskComponentsTab'
import { TaskTWorkUnitTab } from './TaskTWorkUnitTab'
import { DropDownTabsContainer } from 'components/DropDownTabsContainer/DropDownTabsContainer'
import { ITaskTemplateState, ITaskTemplateStateComponent } from 'types/task-templates.interface'
import { taskTemplatesInitialState } from './taskData'
import { TaskTLogicTab } from './TaskTLogicTab'
import { TaskTDesignTab } from './TaskTDesignTab'

export const EditTaskTemplate = () => {
  const [active, setActive] = useState('Operation')
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { singleTaskTemplate } = useSelector((store: RootState) => store.taskTemplates)
  const [state, setState] = useState<ITaskTemplateState>(taskTemplatesInitialState)
  const [initialComponents, setInitialCompoents] = useState([])

  useEffect(() => {
    dispatch(getSingleTaskTemplate(parseInt(id)))

    return () => {
      dispatch(clearTaskTemplate())
    }
  }, [])

  useEffect(() => {
    if (singleTaskTemplate) {
      setState(singleTaskTemplate)
      setInitialCompoents(singleTaskTemplate.components)
    }
  }, [singleTaskTemplate, singleTaskTemplate?.components])

  const handleSubmit = async () => {
    if (!state.operationTypeId) {
      toast.warn('Task capability must be selected.')
      return
    }
    if (state.components.some((c) => !c.componentType)) {
      toast.warn('Components must have set component type.')
      return
    }
    if (state.operationTypeParameterValues.some((t) => !t.minimumValue || (t.parameterId == 1 && !t.desiredValue))) {
      toast.warn('Capabilities must have set value.')
      return
    }
    if (state.toolTypeComponents.some((t) => !t.name)) {
      toast.warn('Tool type components must have set name.')
      return
    }
    const found = state.toolTypeComponents.find(c => c.component.value == 2)

    if (found && !found.unit?.value) {
      toast.warn('Please select item unit.')
      return
    }


    dispatch(editTaskTemplate({ ...state, id: Number(id) })).then(r => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // TODO Naci bolje resenje
      if (r.error) {
        return
      } else {
        window.location.reload()
      }
    })

  }

  const handleLogicSubmit = async (functionType: number) => {
    if ((state.components as ITaskTemplateStateComponent[]).some(c => c.componentType == 2 && !c.functionId)) {
      toast.warn('All calculated compoonents must be connected to functions')
      return
    }
    await dispatch(addLogic({ functionType, components: state.components.filter(c => c.componentType == 2), id }))
  }

  const renderTabs = () => {
    switch (active) {
      case 'Operation':
        return (
          <TaskOperationTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Logic':
        return (
          <TaskTLogicTab
            state={state}
            setState={setState}
            handleLogicSubmit={handleLogicSubmit}
          />
        )
      case 'Design':
        return (
          <TaskTDesignTab
            state={state}
            setState={setState}
            handleSubmit={handleSubmit}
          />
        )
      case 'Work Unit Amounts':
        return <TaskTWorkUnitTab state={state} setState={setState} handleSubmit={handleSubmit} />
      case 'Components':
        return <TaskComponentsTab state={state} setState={setState} handleSubmit={handleSubmit} />
      default:
        return (
          <TaskOperationTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
    }
  }

  const handleLogicTab = () => {
    if (JSON.stringify(state.components) === JSON.stringify(initialComponents)) {
      setActive('Logic')
    } else {
      toast.warn('Please save the task first')
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between center>
          <H1WithBack arrow title={'Edit ' + (state.name ? state.name : '')} />
          <DropDownTabsContainer
            tabs={['Operation', 'Design', 'Logic', 'Components', 'Work Unit Amounts']}
            setActive={setActive}
            customHandleClick={{ fn: handleLogicTab, tab: 'Logic' }}
            active={active}
          />
        </Flex>
        <Separator />
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
