/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { Flex, Button, Animation, TabFooter } from 'styling/GlobalStyles'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Xarrow, { Xwrapper } from 'react-xarrows'
import { SingleOutput } from './DragComponents/SingleOutput'
import { SingleInput } from './DragComponents/SingleInput'
import { SingleFunction } from './DragComponents/SingleFunction'
import { v4 } from 'uuid'
import './DragComponents/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ClearButton } from 'components/Button/ClearButton'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IWorkingFunctions, ITaskTemplateStateComponent, ITaskTemplatesTab } from 'types/task-templates.interface'
import { AppDispatch, RootState } from 'store'
import { getTasksTemplateLogics } from 'features/taskTemplates/taskTemplatesSlice'
import { IGetAllLogicsSingleResponse } from 'types/api/tasksTemplatesApi.interface'
import { DropDownTabsContainer } from 'components/DropDownTabsContainer/DropDownTabsContainer'

export const TaskTLogicTab = ({ state, setState, handleLogicSubmit }: ITaskTLogicTab) => {
  const [inputFunctionLines, setInputFunctionLines] = useState([]) // lines from Input Component to Functions Input
  const [functionOutputLines, setFunctionOutputLines] = useState([]) // lines from Function Output to Function Input
  const [functionOutputInputLines, setFunctionOutputInputLines] = useState([]) // lines from Functions output to Calculated component
  const [allPresentFunctions, setAllPresentFunctions] = useState([])
  const components: ITaskTemplateStateComponent[] = state.components
  const [addedButNotConnected, setAddedButNotConnected] = useState<IWorkingFunctions[]>([])
  const dispatch = useDispatch<AppDispatch>()
  const { tasksLogics } = useSelector((state: RootState) => state.taskTemplates)
  const [tab, setTab] = useState(!state.functionType || state.functionType == 1 ? 'Internal functions' : 'AI functions')
  const inputs = components
    .filter((inp) => inp.componentType == 1)
    .map((inp) => ({ name: inp.name, id: 'inp' + inp.id, component: inp.component }))
  const outputs = components
    .filter((out) => out.componentType == 2)
    .map((out) => ({ name: out.name, id: 'out' + out.id }))

  useEffect(() => {
    dispatch(getTasksTemplateLogics(tab !== 'Internal functions'))
  }, [tab])


  useEffect(() => {
    if (tasksLogics.length > 0) {
      const fetchedAllLogics: IGetAllLogicsSingleResponse[] = tasksLogics
      const localFOLines = []
      const localIFLines = []
      const functionsFromOutputs = components.filter(o => o.componentType == 2 && o.functionId).map(c => {
        const fn = fetchedAllLogics.find(f => f.fid == c.functionId)
        localFOLines.push({ start: 'funOutput_' + fn.fid + '-' + fn.outputs[c.functionResultIndex].component, end: 'out' + c.id })
        if (c.inputParameters && c.inputParameters.length > 0) {
          for (let i of c.inputParameters) {
            if (typeof i.value == 'number') {
              const foundInputComponent = components.filter(cnp => cnp.componentType == 1).find(cnp => cnp.id == i.value)
              localIFLines.push({ start: 'inp' + i.value, end: 'funInput_' + fn.fid + '-' + foundInputComponent.component })
            }
          }
        }

        return { fid: c.functionId, name: fn.name, outputs: fn.outputs, inputs: fn.inputs }
      })

      let notAddedInputsToFunctionInputs = []

      addedButNotConnected.forEach(nc => nc.input.forEach(inc => {
        if (inc.tempConnectedInput) {
          notAddedInputsToFunctionInputs.push({ start: 'inp' + inc.tempConnectedInput.componentId, end: 'funInput_' + nc.fid + '-' + inc.component })
        }
      }))


      setInputFunctionLines([...localIFLines, ...notAddedInputsToFunctionInputs])
      setFunctionOutputInputLines(localFOLines)


      setAllPresentFunctions([...functionsFromOutputs])

      if (functionsFromOutputs.some(f => addedButNotConnected.some(fa => fa.fid == f.fid))) {
        setAddedButNotConnected(prev => prev.filter(f => functionsFromOutputs.find(fn => fn.fid == f.fid)?.fid !== f.fid))
      }

    }
  }, [tasksLogics, state.components, addedButNotConnected])


  const getAllFunctionsWithNotConnectedOnes = (): IWorkingFunctions[] => {

    let allLogics: IGetAllLogicsSingleResponse[] = [...tasksLogics]
    for (let nc of addedButNotConnected) {
      const found = allLogics.find(l => l.fid == nc.fid)
      if (found) {
        allLogics = allLogics.filter(alf => alf.fid !== found.fid)
      }
    }
    //  @ts-ignore
    return [...allLogics, ...addedButNotConnected]
  }


  const handleAddFunc = (item: IGetAllLogicsSingleResponse) => {
    setAddedButNotConnected(prev => [...prev, { ...item, input: item.inputs, notAdded: true }])
  }

  const handleClearLogic = () => {
    setState(prev => {
      let copy = structuredClone(prev)
      copy.components = copy.components.map((c: ITaskTemplateStateComponent) => ({ ...c, functionId: null, functionResultIndex: null, inputParameters: null }))
      setAddedButNotConnected([])
      return copy
    })
  }

  const displayFunctions: IWorkingFunctions[] = [...allPresentFunctions, ...addedButNotConnected]

  const handleActive = (tab: string) => {
    handleClearLogic()
    setTab(tab)
  }


  return (
    <Animation>
      <Flex between gap='2rem'>
        <DndProvider backend={HTML5Backend}>
          <div className='container'>
            <Xwrapper>
              <div className='input-container'>
                {inputs.map((input) => {
                  return (
                    <SingleInput
                      key={input.id}
                      name={input.name}
                      id={input.id}
                      component={input.component}
                    />
                  )
                })}
              </div>
              <div className='functions-container'>
                {displayFunctions.map((func) => {
                  return (
                    <SingleFunction
                      data={func}
                      key={func.fid}
                      setAddedButNotConnected={setAddedButNotConnected}
                      allFunctions={getAllFunctionsWithNotConnectedOnes()}
                      setState={setState}
                    />
                  )
                })}
              </div>
              <div className='output-container'>
                {outputs.map((output) => {
                  return (
                    <SingleOutput
                      name={output.name}
                      id={output.id}
                      key={output.id}
                      setState={setState}
                      state={state}
                      allFunctions={displayFunctions}
                    />
                  )
                })}
              </div>
              {inputFunctionLines.map((item) => {
                return (
                  <Xarrow
                    key={v4()}
                    start={item.start}
                    end={item.end}
                    color={'#FF9900'}
                    strokeWidth={1}
                    headShape={'circle'}
                    headSize={10}
                    startAnchor='bottom'
                    endAnchor='top'
                  />
                )
              })}
              {functionOutputInputLines.map((item) => {
                return (
                  <Xarrow
                    key={v4()}
                    start={item.start}
                    end={item.end}
                    color={'#464255'}
                    strokeWidth={1}
                    headShape={'circle'}
                    headSize={10}
                    startAnchor='bottom'
                    endAnchor='top'

                  />
                )
              })}
              {functionOutputLines.map((item) => {
                return (
                  <Xarrow
                    key={v4()}
                    start={item.start}
                    end={item.end}
                    color={'#DADADA'}
                    strokeWidth={1}
                    headShape={'circle'}
                    headSize={10}
                    endAnchor='top'
                    startAnchor='bottom'
                  />
                )
              })}
            </Xwrapper>
          </div>
        </DndProvider>
        <Flex column gap='1rem'>
          <DropDownTabsContainer
            tabs={['Internal functions', 'AI functions']}
            setActive={handleActive}
            active={tab}
          />
          <ItemsPick
            link={tab == 'Internal functions' ? 'task-template/logics' : 'task-template/logics/ai'}
            width='100%'
            selectedList={displayFunctions.map(f => ({ name: f.name, id: f.fid, fid: f.fid }))}
            oneColumn
            noScroll
            parserFunction={(resp: IGetAllLogicsSingleResponse[]) => resp}
            handleAdd={handleAddFunc}
            placeholder='Search Functions'
            leaveDuplicates
            customSelectedProp='fid'
            resetList
          />
        </Flex>
      </Flex>
      <TabFooter contract>
        <Flex gap='1rem'>
          <ClearButton text='Clear logic' onClick={handleClearLogic} />
          <CancelBtn />
          <Button orange onClick={() => handleLogicSubmit(tab == 'Internal functions' ? 1 : 2)}>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}


interface ITaskTLogicTab extends ITaskTemplatesTab {
  handleLogicSubmit: (functionType: number) => void
}