import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import { P } from 'styling/GlobalStyles';

export const CustomLabel = ({
  viewBox,
  labelText,
  value,
  fontSize
}: {
  viewBox?: { cx: number; cy: number }
  labelText?: string
  value?: string
  fontSize?: number
}) => {
  const { cx, cy } = viewBox

  return (
    <g>
      <text
        x={100}
        y={100}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='15'
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fill='#464255'
        fontSize={fontSize ?? 17}
        fontWeight='700'
      >
        {value}
      </text>
    </g>
  )
}


export const Chart = ({ data, modifiedData = [], chartColors = [], customLabel, fontSize, notAvailable }: IChart) => {
  return (
    <ResponsiveContainer width={100} height={100}>
      {!notAvailable ? <PieChart width={80} height={80}>
        <Pie data={modifiedData} dataKey='value' innerRadius={30} outerRadius={50}>
          {modifiedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
          ))}
          <Label
            content={
              <CustomLabel fontSize={fontSize} value={customLabel ?? (((data[0]?.value * 100) / data[1]?.value).toFixed(0) + '%')} />
            }
            position='center'
          />
        </Pie>
      </PieChart> : <P style={{ fontStyle: 'italic' }}  >Not Available</P>}
    </ResponsiveContainer>
  )
}

interface IChart {
  data: { value: number, name: string }[]
  modifiedData: { value: number, name: string }[]
  chartColors: string[]
  color: string
  customLabel?: string
  fontSize?: number
  notAvailable?: boolean
}
