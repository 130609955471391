import { baseUrl } from './../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { formatParams, handleErrors } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IAddTaskState, IGenerateTask, ITaskSlice } from 'types/tasks.interface'
import {
  IGenerateTaskResponse,
  IGenerateWorkflowRequest,
  IGenerateWorkflowResponse,
  IGetManagerTasksSingleResponse,
} from 'types/api/tasksApi.interface'
import { toast } from 'react-toastify'

const initialState: ITaskSlice = {
  isLoading: false,
  tasks: [],
  singleTask: null,
  noTasks: false,
  generatedTask: null,
  generatedWorkflow: null,
  isLoadingGenerate: false,
}

export const generateWorkflow = createAsyncThunk(
  'tasks/generateWorkflow',
  async ({ config, departmentId }: { config: IAddTaskState; departmentId: number }, thunkAPI) => {
    try {
      const req: IGenerateWorkflowRequest = {
        departmentId: departmentId,
        criteria: config.criteria.value,
        workflowTemplateId: config.task.value,
        startDate: config.date,
        items: config.items.map((i) => {
          const toolComponentValues = []
          if (i.item) {
            toolComponentValues.push({
              taskTemplateToolTypeComponentId: i.item.taskTemplateToolTypeComponentId,
              toolComponentId: i.item.value,
              amount: i.item.amount,
            })
          }

          if (i.machine) {
            toolComponentValues.push({
              taskTemplateToolTypeComponentId: i.machine.taskTemplateToolTypeComponentId,
              toolComponentId: i.machine.value,
            })
          }

          return {
            locationId: i.location.value,
            workflowTemplateItemId: i.workflowTemplateItemId,
            toolComponentValues,
            componentValues: i.components
              .filter((c) => c.componentType == 1)
              .map((c) => ({ taskComponentId: c.id, value: c.value })),
          }
        }),
      }
      const resp: AxiosResponse<IGenerateWorkflowResponse> = await baseUrl.post('workflow', req)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getTasks = createAsyncThunk('tasks/getTasks', async (params: any, thunkAPI) => {
  try {
    params = formatParams(params)
    const resp: AxiosResponse<IGetManagerTasksSingleResponse> = await baseUrl.get('task/manager', {
      params,
    })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deleteTask = createAsyncThunk(
  'tasks/deleteTask',
  async ({ id, type }: { id: number; type: 'task' | 'workflow' }, thunkAPI) => {
    try {
      await baseUrl.delete('task/' + id)
      return { id, type }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const generateTask = createAsyncThunk(
  'tasks/generateTask',
  async ({ config, departmentId }: { config: IAddTaskState; departmentId: number }, thunkAPI) => {
    const toolComponentValues = []
    if (config.item) {
      toolComponentValues.push({
        taskTemplateToolTypeComponentId: config.item.taskTemplateToolTypeComponentId,
        amount: config.item.amount,
        toolComponentId: config.item.value,
      })
    }
    if (config.machine) {
      toolComponentValues.push({
        taskTemplateToolTypeComponentId: config.machine.taskTemplateToolTypeComponentId,
        toolComponentId: config.machine.value,
      })
    }

    const gTask: IGenerateTask = {
      departmentId: departmentId,
      locationId: config.location.value,
      taskTemplateId: config.task.value,
      criteria: config.criteria.value,
      componentValues: config.components.map((c) => ({ taskComponentId: c.id, value: c.value })),
      toolComponentValues,
      isTriggered: false,
      startDate: config.date,
    }
    try {
      const resp: AxiosResponse<IGenerateTaskResponse> = await baseUrl.post('task/generate', gTask)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const confirmTask = createAsyncThunk('tasks/confirmTask', async (id: number, thunkAPI) => {
  try {
    const resp: AxiosResponse<any> = await baseUrl.post('task/' + id + '/confirm')
    toast.success('Task successfully confirmed')
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const confirmWorkflow = createAsyncThunk(
  'tasks/confirmTask',
  async (id: number, thunkAPI) => {
    try {
      const resp: AxiosResponse<any> = await baseUrl.patch('workflow/' + id + '/confirm')
      toast.success('Workflow successfully confirmed')
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clearTask: (state) => {
      state.singleTask = null
    },
    clearTasksList: (state) => {
      state.tasks = []
    },
    clearGenerated: (state) => {
      state.generatedTask = null
      state.generatedWorkflow = null
    },
  },
  extraReducers: {
    [getTasks.pending.type]: (state) => {
      state.isLoading = true
    },
    [getTasks.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.tasks = payload
      state.noTasks = state.tasks.length < 1
    },
    [getTasks.rejected.type]: (state, payload) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [generateTask.pending.type]: (state) => {
      state.isLoadingGenerate = true
    },
    [generateTask.fulfilled.type]: (state, { payload }) => {
      state.isLoadingGenerate = false
      state.generatedTask = payload
    },
    [generateTask.rejected.type]: (state, { payload }) => {
      state.isLoadingGenerate = false
      handleErrors(payload)
    },
    [generateWorkflow.pending.type]: (state) => {
      state.isLoadingGenerate = true
    },
    [generateWorkflow.fulfilled.type]: (state, { payload }) => {
      state.isLoadingGenerate = false
      state.generatedWorkflow = payload
    },
    [generateWorkflow.rejected.type]: (state, { payload }) => {
      state.isLoadingGenerate = false
      handleErrors(payload)
    },
    [confirmTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [confirmTask.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [confirmTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [confirmWorkflow.pending.type]: (state) => {
      state.isLoading = true
    },
    [confirmWorkflow.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [confirmWorkflow.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteTask.fulfilled.type]: (state, { payload }) => {
      state.tasks = state.tasks.filter((t) => t[payload.type].id !== payload.id)
      state.noTasks = !state.tasks.length
      state.isLoading = false
      toast.success('Task deleted successfully')
    },
    [deleteTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default tasksSlice.reducer
export const { clearTasksList, clearTask, clearGenerated } = tasksSlice.actions
// {
//   "id": 0,
//   "name": "string",
//   "createdAt": "2025-03-13T09:49:59.277Z",
//   "items": [
//     {
//       "id": 0,
//       "level": 0,
//       "index": 0,
//       "data": {
//         "parentItems": [
//           {
//             "level": 0,
//             "index": 1,
//             "linkType": 1
//           }
//         ]
//       },
//       "taskTemplate": {
//         "id": 0,
//         "name": "string",
//         "operationType": {
//           "operationId": 0,
//           "operationName": "string",
//           "operationTypeId": 0,
//           "operationTypeName": "string",
//           "operationTypeParameterValues": [
//             {
//               "operationTypeParameterId": 0,
//               "parameterName": "string",
//               "parameterId": 0,
//               "unitName": "string",
//               "unitId": 0,
//               "desiredValue": 0,
//               "minimumValue": 0
//             }
//           ],
//           "parameters": [
//             {
//               "id": 0,
//               "name": "string",
//               "parameterId": 0,
//               "unit": "string",
//               "unitId": 0
//             }
//           ]
//         },
//         "shouldUseLocationTracking": true,
//         "imagePresignedUploadUrl": "string",
//         "components": [
//           {
//             "id": 0,
//             "name": "string",
//             "component": 1,
//             "componentType": 1,
//             "functionType": 1,
//             "functionId": 0,
//             "functionResultIndex": 0,
//             "inputParameters": [
//               {}
//             ]
//           }
//         ],
//         "toolTypeComponents": [
//           {
//             "id": 1,
//             "name": "string",
//             "toolTypeComponent": {
//               "id": 1,
//               "name": "Machinery"
//             },
//             "unit": {
//               "id": 0,
//               "name": "string",
//               "coefficient": 0
//             }
//           }
//         ],
//         "workerComponents": [
//           {
//             "id": 0,
//             "field": {
//               "id": 0,
//               "name": "string",
