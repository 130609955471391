import React, { useEffect, useState } from 'react'
import {
  NavBarWrapper,
  NavBarInput,
  NavBarUser,
  UserInfo,
  NavBarFilterContainer,
  NavBarFilterSelect,
  NavBarFilterOption,
  Profile,
  UserButtonsContiner,
  UserBtn,
} from './NavBar.styled'

import { ProfilePicture, Flex } from '../../styling/GlobalStyles'
import Chevron from '../../assets/icons/Chevron'
import Avatar from '../../assets/images/profileAvatar.png'
import { NavBarSearchIcon } from '../../assets/icons/NavBarSearchIcon'
import useAuth from '../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { RootState } from 'store'

export const NavBar = ({ input, placeholder, filter, setParams, limit, searchFields, queryName, defaultValue }: INavBar) => {
  const { isSidebarOpen } = useSelector((store: RootState) => store.globals)
  const { user, logout } = useAuth()
  const [show, setShow] = useState(false)
  // eslint-disable-next-line
  const [profileImage, setProfileImage] = useState<any>()
  const [query, setQuery] = useState<string>(defaultValue)
  useEffect(() => {
    if (user?.getImagePresignedUrl) {
      setProfileImage(user?.getImagePresignedUrl)
    }
  })


  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setParams((prev: any) => {
      const copy = { ...prev }
      if (queryName) {
        copy[queryName] = query
      } else {
        copy.q = query
      }
      copy.page = 1
      copy.limit = limit ? limit : 30
      if (searchFields) {
        copy.searchFields = searchFields
      }
      return copy
    })
  }

  const handleClearQuery = () => {
    setQuery('')
    setParams((prev: any) => {
      const copy = { ...prev }
      if (queryName) {
        copy[queryName] = ''
      }
      copy.q = ''
      return copy
    })
  }

  useEffect(() => {
    if (!user) {
      logout()
    }
  }, [])

  return (
    <NavBarWrapper sidebar={isSidebarOpen}>
      <form onSubmit={handleSubmit}>
        {input ? (
          <NavBarInput>
            <button>{NavBarSearchIcon}</button>
            <input value={query} type='text' placeholder={placeholder} onChange={handleInput} />
            {query && (
              <Flex center onClick={handleClearQuery}>
                <CloseIcon black />
              </Flex>
            )}
          </NavBarInput>
        ) : null}
      </form>
      {!filter ? (
        <NavBarUser>
          <Profile>
            <UserInfo>
              <h3>
                {user?.firstName} {user?.lastName}
              </h3>
              <span>{user?.position}</span>
            </UserInfo>

            <ProfilePicture onClick={() => setShow(!show)}>
              <img
                src={profileImage ?? Avatar}
                alt='user-img'
                onError={({ currentTarget }) => (currentTarget.src = Avatar)}
              />
            </ProfilePicture>
          </Profile>
          {show && (
            <UserButtonsContiner>
              <UserBtn show={show} onClick={() => logout()}>
                Logout
              </UserBtn>
            </UserButtonsContiner>
          )}
        </NavBarUser>
      ) : (
        <NavBarFilterContainer>
          <NavBarFilterSelect>
            <NavBarFilterOption>All Capabilities</NavBarFilterOption>
            <NavBarFilterOption>Some Capabilities</NavBarFilterOption>
          </NavBarFilterSelect>
          <Chevron />
        </NavBarFilterContainer>
      )}
    </NavBarWrapper>
  )
}


interface INavBar {
  input?: boolean
  placeholder?: string
  filter?: boolean
  handleChange?: () => void
  handleSubmit?: () => void
  setParams?: React.Dispatch<React.SetStateAction<any>>
  limit?: number
  searchFields?: string[]
  queryName?: string
  defaultValue?: string
}