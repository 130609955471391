/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import {
    Button,
    Table,
    Th,
    Tr,
    Flex,
    MainHeadline,
    PageWrapper,
    Separator,
} from '../../styling/GlobalStyles'
import { NavBar } from '../../components/NavBar/NavBar'
import { InfoIcon } from 'assets/icons/InfoIcon'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { Options } from 'components/Options/Options'
import { setConfirmModal } from 'features/global/globalSlice'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Td } from 'components/Td/Td'
import { deleteTask, getTasks } from 'features/tasks/tasksSlice'
import { formatDateForDisplay } from 'utils/helpers'
import { taskStatusesData } from 'pages/Dashboard/dashboardData'
import { DatePickerInput } from 'components/DatePicker/DatePicker'

export const Tasks = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const { noTasks, tasks } = useSelector((state: RootState) => state.tasks)
    const [date, setDate] = useState(new Date())

    useEffect(() => {
        dispatch(getTasks({ date }))
    }, [date])

    const handleDeleteTask = (id: number, type: 'task' | 'workflow') => {
        dispatch(
            setConfirmModal({
                isOpen: true,
                onSave: async () => {
                    await dispatch(deleteTask({ id, type }))
                },
                word: 'delete task',
            }),
        )
    }

    return (
        <>
            <NavBar defaultValue={''} input placeholder='Search Taskss' limit={10} />
            <PageWrapper>
                <Flex between center>
                    <MainHeadline>Tasks</MainHeadline>
                    <Flex gap='1rem' center >
                        <DatePickerInput nomarg noLabel date={date} setDate={setDate} />
                        <Button orange onClick={() => navigate('add')}>
                            Add Task
                        </Button>
                    </Flex>
                </Flex>
                <Separator />
                {noTasks ? (
                    <NoItems title='Tasks' />
                ) : (
                    <Table>
                        <Th>
                            <Td w='5%'></Td>
                            <Td w='10%' noTooltip text='Type' />
                            <Td w='25%' noTooltip text='Name' />
                            <Td w='17%' noTooltip text='Estimated start date' />
                            <Td w='17%' noTooltip text='Actual start date' />
                            <Td w='17%' noTooltip text='Status' />
                            <Td w='5%'></Td>
                        </Th>
                        {noTasks ? (
                            <NoItems title='Tasks' />
                        ) : (
                            tasks.map((item) => {

                                return (
                                    <Tr small key={item.workflow?.id ?? item.task.id}>
                                        <Td w='5%'>{InfoIcon}</Td>
                                        <Td w='10%' noTooltip text={item.type == 'workflow' ? 'Workflow' : 'Task'} />
                                        <Td w='25%' noTooltip text={item.workflow?.name ?? item.task.name} />
                                        <Td w='17%' noTooltip text={formatDateForDisplay(item.workflow?.estimatedStartDate ?? item.task.estimatedStartDate)} />
                                        <Td w='17%' noTooltip text={(item.workflow?.actualStartDate || item.task?.actualStartDate) ? formatDateForDisplay(item.workflow?.actualStartDate ?? item.task.actualStartDate) : '/'} />
                                        <Td w='17%' noTooltip text={taskStatusesData[item.workflow?.status ?? item.task.status].name} />
                                        <Td w='5%'>
                                            {!item.workflow && <Options
                                                item={item}
                                                handleRemove={() => handleDeleteTask(item.task ? item.task.id : item.workflow.id, item.task ? 'task' : 'workflow')}
                                                title='Tasks'
                                            />}
                                        </Td>
                                    </Tr>
                                )
                            })
                        )}
                    </Table>
                )}
            </PageWrapper>

        </>
    )
}
