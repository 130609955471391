import { EditMachinery } from 'pages/Machinery/EditMachinery'
import { EditLocation } from './../pages/Locations/EditLocation'
import { EditEmployee } from 'pages/Employees/EditEmployee'
import { SingleEmployee } from 'pages/Employees/SingleEmployee/SingleEmployee'
import { AddShift } from 'pages/Shifts/AddShift'
import { Zones } from 'pages/Zones/Zones'
import { Employees, Machinery, Shifts } from '../pages'
import { AddEmployeer } from '../pages/Employees/AddEmployee'
import { AddItem } from '../pages/Inventory/Items/AddItem'
import { InventoryItems } from '../pages/Inventory/Items/InventoryItems'
import { AddSupplier } from '../pages/Inventory/Suppliers/AddSupplier'
import { InvetorySuppliers } from '../pages/Inventory/Suppliers/InvetorySuppliers'
import { AddMachinery } from '../pages/Machinery/AddMachinery'
import { SingleMachineryCategory } from '../pages/Machinery/SingleMachineryCategory'
import { Locations } from 'pages/Locations/Locations'
import { AddLocation } from 'pages/Locations/AddLocation'
import { Departments } from 'pages/Departments/Departments'
import { EditDepartment } from 'pages/Departments/EditDepartment'
import { AddDepartment } from 'pages/Departments/AddDepartment'
import { EditItem } from 'pages/Inventory/Items/EditItem'
import { EditSupplier } from 'pages/Inventory/Suppliers/EditSupplier'
import { Costs } from 'pages/Finances/Costs/Costs'
import { Reports } from 'pages/Reports/Reports'
import { GlobalView } from 'pages/GlobalView/GlobalView'
import { SingleReport } from 'pages/Reports/SingleReport'
import { ClientDashboard } from 'pages/Dashboard/Dashboard'
import { DBEmployees } from 'pages/Dashboard/DashboardEmplyees/DBEmployees'
import { DBSingleEmployee } from 'pages/Dashboard/DashboardEmplyees/DBSingleEmployee'
import { DBTasks } from 'pages/Dashboard/DashboardTasks/DBTasks'
import { DBSingleTask } from 'pages/Dashboard/DashboardTasks/DBSingleTask'
import { DBMachines } from 'pages/Dashboard/DashboardMachines/DBMachines'
import { DBSingleMachine } from 'pages/Dashboard/DashboardMachines/DBSingleMachine'
import { AddRole } from 'pages/ResourcesAdmin/Roles/AddRole'
import { EditRole } from 'pages/ResourcesAdmin/Roles/EditRole'
import { ClientRoles } from 'pages/ResourcesAdmin/Roles/ClientRoles'
import { SingleRole } from 'pages/ResourcesAdmin/Roles/SingleRole'
import { DBTaskCycles } from 'pages/Dashboard/DashboardTasks/DBTaskCycles'
import { DBWorkflows } from 'pages/Dashboard/DashboardTasks/DBWorkflows'
import { Tasks } from 'pages/Tasks/Tasks'
import { AddTask } from 'pages/Tasks/AddTask'
import { ViewTask } from 'pages/Tasks/ViewTask'

export const clientRoutes = [
  {
    path: '/',
    Component: Employees,
  },
  {
    path: '/global-view',
    Component: GlobalView,
  },
  {
    path: '/dashboard',
    Component: ClientDashboard,
  },
  {
    path: '/dashboard/employees',
    Component: DBEmployees,
  },
  {
    path: '/dashboard/employees/:id',
    Component: DBSingleEmployee,
  },
  {
    path: '/dashboard/tasks',
    Component: DBTasks,
  },
  {
    path: '/dashboard/cycles',
    Component: DBTaskCycles,
  },
  {
    path: '/dashboard/tasks/:id',
    Component: DBSingleTask,
  },
  {
    path: '/dashboard/machines',
    Component: DBMachines,
  },
  {
    path: '/dashboard/machines/:id',
    Component: DBSingleMachine,
  },
  {
    path: '/dashboard/workflows',
    Component: DBWorkflows,
  },
  {
    path: '/employees',
    Component: Employees,
  },
  {
    path: '/employees/:id',
    Component: SingleEmployee,
  },
  {
    path: 'employees/add',
    Component: AddEmployeer,
  },
  {
    path: 'roles/add',
    Component: AddRole,
  },
  {
    path: 'roles/:id',
    Component: SingleRole,
  },
  {
    path: 'roles/:id/edit',
    Component: EditRole,
  },
  {
    path: 'roles',
    Component: ClientRoles,
  },
  {
    path: 'employees/:id/edit',
    Component: EditEmployee,
  },
  {
    path: 'shifts',
    Component: Shifts,
  },
  {
    path: 'shifts/add',
    Component: AddShift,
  },
  {
    path: 'machinery',
    Component: Machinery,
  },
  {
    path: 'machinery/add',
    Component: AddMachinery,
  },
  {
    path: 'machinery/:id',
    Component: SingleMachineryCategory,
  },
  {
    path: 'machinery/:categoryId/:id',
    Component: EditMachinery,
  },
  {
    path: 'inventory-items',
    Component: InventoryItems,
  },
  {
    path: 'inventory-items/add',
    Component: AddItem,
  },
  {
    path: 'inventory-items/edit/:id',
    Component: EditItem,
  },
  {
    path: 'inventory-supliers',
    Component: InvetorySuppliers,
  },
  {
    path: 'inventory-supliers/add',
    Component: AddSupplier,
  },
  {
    path: 'inventory-supliers/:id',
    Component: EditSupplier,
  },
  {
    path: 'locations',
    Component: Locations,
  },
  {
    path: 'locations/add',
    Component: AddLocation,
  },
  {
    path: 'locations/edit/:id',
    Component: EditLocation,
  },
  {
    path: 'reports',
    Component: Reports,
  },
  {
    path: 'reports/:id',
    Component: SingleReport,
  },
  {
    path: 'zones',
    Component: Zones,
  },
  {
    path: 'departments',
    Component: Departments,
  },
  {
    path: 'departments/:id',
    Component: EditDepartment,
  },
  {
    path: 'departments/add',
    Component: AddDepartment,
  },
  {
    path: 'finances-costs',
    Component: Costs,
  },
  {
    path: 'tasks',
    Component: Tasks,
  },
  {
    path: 'tasks/add',
    Component: AddTask,
  },
  {
    path: 'tasks/:id',
    Component: ViewTask,
  },
]
