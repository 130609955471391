import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { ISelectValue } from 'constants/globalTypes'
import { generateTask } from 'features/tasks/tasksSlice'
import { criteria } from 'pages/TaskTemplates/taskData'
import { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { Box, Button, Flex, H2, P, Separator, Title } from 'styling/GlobalStyles'
import { IDepartmentForTaskWithTypes, IGeneratedTaskComponentValue } from 'types/api/tasksApi.interface'
import { IAddTaskState } from 'types/tasks.interface'
import { formatDateForDisplay, getTimeFromDate, setState } from 'utils/helpers'

export const TaskSection = ({ department, handleChange, config, parseToolComponents, setConfig, configRef }: ITaskSection) => {
    const dispatch = useDispatch<AppDispatch>()
    const { generatedTask } = useSelector((state: RootState) => state.tasks)
    const { inputComponents } = useSelector((state: RootState) => state.taskTemplates)

    const handleGenerateTask = async () => {
        const taskRequiresMachine = inputComponents.taskTemplate.toolTypeComponents.some(c => c.name == 'Machine')
        const taskRequiresItem = inputComponents.taskTemplate.toolTypeComponents.some(c => c.name == 'Item')
        if (!config.location || !config.task || !config.criteria || config.components.some(c => !c.value || (taskRequiresMachine && !config.machine) || (taskRequiresItem && (!config.item || !config.item.amount)))) {
            toast.warn('Please fill all fields')
            return
        }
        await dispatch(generateTask({ config, departmentId: department.id }))
        configRef.current.scrollTop = configRef.current.scrollHeight;
    }

    const handleComponent = (e: ChangeEvent<HTMLInputElement>) => {
        const { valueAsNumber, name } = e.target
        const index = config.components.findIndex((c) => c.name == name)
        setConfig((prev) => {
            const copy = { ...prev }
            copy.components[index].value = valueAsNumber
            return copy
        })
    }


    const handleAmount = (e: ChangeEvent<HTMLInputElement>) => {
        setConfig((prev) => ({ ...prev, item: { ...prev.item, amount: e.target.valueAsNumber } }))
    }


    const handleToolTypeComponents = (
        value: ISelectValue,
        name: string,
        taskTemplateToolTypeComponentId: number,
    ) => {
        setState(setConfig, name, { ...value, taskTemplateToolTypeComponentId })
    }


    return <>
        <HonestSelectInput
            labelText='Location'
            options={department.locations.map(l => ({ label: l.name, value: l.id }))}
            onChange={handleChange}
            name='location'
        />
        <HonestSelectInput
            labelText='Criteria'
            options={criteria}
            onChange={handleChange}
            name='criteria'
            defaultValue={config.criteria}
        />
        {inputComponents?.taskTemplate?.components.map(c => {
            const value = config.components.find(cp => cp.name == c.name)?.value
            return <Input key={c.id} type='number' value={value} labelText={c.name + (c.component == 25 ? ' (min)' : '')} name={c.name} onChange={handleComponent} placeholder='Enter value' />
        })}
        {
            inputComponents?.taskTemplate.toolTypeComponents.find(c => c.toolTypeComponent.name == 'Machinery') && <HonestSelectAsyncInput
                name='machine'
                labelText='Machinery'
                onChange={(v, n) => handleToolTypeComponents(v, n, inputComponents?.taskTemplate.toolTypeComponents.find(c => c.toolTypeComponent.name == 'Machinery').id)}
                link='machines'
                parserFunction={parseToolComponents}
            />
        }
        {
            inputComponents?.taskTemplate.toolTypeComponents.find(c => c.toolTypeComponent.name == 'Item') && <Flex gap='1rem' >
                <HonestSelectAsyncInput
                    name='item'
                    labelText='Item'
                    onChange={(v, n) => handleToolTypeComponents(v, n, inputComponents?.taskTemplate.toolTypeComponents.find(c => c.toolTypeComponent.name == 'Item').id)}
                    link='inventory-items'
                    parserFunction={parseToolComponents}
                />
                <Input width='25%' labelText='Amount' onChange={handleAmount} placeholder='5' type='number' value={config.item?.amount || 0} />
            </Flex>
        }
        <Button orange onClick={handleGenerateTask} >
            Generate
        </Button>
        {
            generatedTask && <>
                <Separator />
                <Flex column gap='1rem' >
                    <Title pad='0'>Generated Task Preview</Title>
                    <TextDisplayCard labelText='Estimated cost' title={generatedTask.task.estimatedCost.toFixed(2)} />
                    <Flex gap='1rem' >
                        <TextDisplayCard width='100%' labelText='Start' title={formatDateForDisplay(generatedTask.task.estimatedStartDate) + ' ' + getTimeFromDate(generatedTask.task.estimatedStartDate)} />
                        <TextDisplayCard width='100%' labelText='End' title={formatDateForDisplay(generatedTask.task.estimatedStartDate) + ' ' + getTimeFromDate(generatedTask.task.estimatedStartDate)} />
                    </Flex>
                    {generatedTask.components.filter(c => c.componentType == 1).map(c => <TextDisplayCard key={c.id} labelText={c.name} title={(c.value as number) + (c.component == 25 ? ' min' : '')} />)}
                    <Box>
                        <H2 mb='0.5' >Workers</H2>
                        {(generatedTask.components.filter(c => c.component == 5)[0].value as IGeneratedTaskComponentValue).users.map(u => <>
                            <TextDisplayCard width='100%' title={u.firstName + ' ' + u.lastName} />
                            <P style={{ marginTop: 5 }} >Machine: {u.executionPlans[0].machineName || 'No'}</P>
                            {u.executionPlans.map(e => <Flex mt='10' gap='1rem' key={e.startTime} >
                                <TextDisplayCard width='100%' labelText='Start' title={formatDateForDisplay(e.startTime) + ' ' + getTimeFromDate(e.startTime)} />
                                <TextDisplayCard width='100%' labelText='End' title={formatDateForDisplay(e.endTime) + ' ' + getTimeFromDate(e.endTime)} />
                            </Flex>)}

                        </>)}
                    </Box>
                </Flex>
            </>
        }
    </>
}

interface ITaskSection {
    handleChange: (value: ISelectValue, name: string) => void
    setConfig: React.Dispatch<React.SetStateAction<IAddTaskState>>
    config: IAddTaskState
    configRef: React.MutableRefObject<any>
    department: IDepartmentForTaskWithTypes
    parseToolComponents: (r: any) => any
}