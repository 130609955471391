import React, { useState } from 'react'
import {
  Button,
  Table,
  Th,
  Tr,
  Flex,
  MainHeadline,
  PageWrapper,
  Separator,
  PageFooter,
} from '../../styling/GlobalStyles'
import { NavBar } from '../../components/NavBar/NavBar'
import { InfoIcon } from 'assets/icons/InfoIcon'
import { GreenCheckIcon } from 'assets/icons/CheckCheckIcon'
import { RedXIcon } from 'assets/icons/RedXIcon'
import { useNavigate } from 'react-router-dom'
import { ITaskTemplate } from 'types/task-templates.interface'
import { useDispatch, useSelector } from 'react-redux'
import { clearTaskTemplatesList, deleteTaskTemplate, duplicateTaskTemplate, getTaskTemplates } from 'features/taskTemplates/taskTemplatesSlice'
import { AppDispatch, RootState } from 'store'
import { Options } from 'components/Options/Options'
import { setConfirmModal } from 'features/global/globalSlice'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Td } from 'components/Td/Td'
import { ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const TaskTemplates = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { allTaskTemplates, totalPages, noTaskTemplates } = useSelector((store: RootState) => store.taskTemplates)
  const [params, setParams] = useState<any>(getParams({ location, limit: 10, ap: { sd: 'a', s: 'name' } }))

  useQueryPagination({ fetchFunctions: [getTaskTemplates], cleanUpFunctions: [clearTaskTemplatesList], params })

  const handleDeleteTask = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteTaskTemplate(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: allTaskTemplates.length < 2,
        },
        word: 'delete task template',
      }),
    )
  }

  const handleDuplicate = (item: ITaskTemplate) => {
    dispatch(duplicateTaskTemplate({ name: item.name + ' copy', taskTemplateId: item.id }))
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Task Templates...' setParams={setParams} limit={10} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Tasks Templates</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Template
          </Button>
        </Flex>
        <Separator />
        {noTaskTemplates ? (
          <NoItems title='Task templates' />
        ) : (
          <Table>
            <Th>
              <Td w='5%'></Td>
              <Td w='27%' noTooltip text='Template' />
              <Td w='17%' noTooltip text='Design' />
              <Td w='17%' noTooltip text='Logic' />
              <Td w='17%'></Td>
              <Td w='5%'></Td>
            </Th>
            {noTaskTemplates ? (
              <NoItems title='Task templates' />
            ) : (
              allTaskTemplates.map((item) => {
                const hasLogic = item?.isLogicAdded
                const hasDesign = item?.components || Array.isArray(item.components)

                return (
                  <Tr small key={item.id}>
                    <Td w='8%'>{InfoIcon}</Td>
                    <Td w='29%' text={item.name} />
                    <Td w='20%' noTooltip svgColor={hasDesign && '#00a368'} >
                      {hasDesign
                        ? GreenCheckIcon
                        : RedXIcon}
                    </Td>
                    <Td w='40%' noTooltip svgColor={hasLogic && '#00a368'} >
                      {hasLogic ? GreenCheckIcon : RedXIcon}
                    </Td>
                    {/* <Td w='30%'>{item.company ? '' : item.company}</Td> */}
                    <Td w='5%'>
                      <Options
                        item={item}
                        handleRemove={() => handleDeleteTask(item.id)}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // TODO
                        handleDuplicate={() => handleDuplicate(item)}
                        title='Task Template'
                      />
                    </Td>
                  </Tr>
                )
              })
            )}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 10)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
