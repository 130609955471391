import { taskStatusesData } from 'pages/Dashboard/dashboardData'
import React, { useEffect } from 'react'
import { Location } from 'react-router-dom'
import { toast } from 'react-toastify'
// click outside REF
export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
// end of click outside REF

export const dataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

// prevent extra char for NUMBER input
export const blockInvalidChar = (e) => ['e', 'E', '-'].includes(e.key) && e.preventDefault()

export const onlyNumbers = (e: React.KeyboardEvent, phone?: boolean) => {
  const regex = phone ? /^[0-9+.]*$/ : /^[0-9+.]*$/
  const valid = regex.test(e.key)
  if (!valid && e.key !== 'Backspace') e.preventDefault()
}

export const onlyLetters = (e) => {
  const regex = /^[A-Za-z\s]*$/
  const valid = regex.test(e.key)

  if (!valid) e.preventDefault()
}

export const phoneValidation = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false
  return true
}

export const blockAllInvalidChar = (e) => {
  const reg = /^([0-9]{1,})[.]([0-9]{2})$/
  // /^[1-9]\d*(\.\d+)?$/
  reg.test(e.key) && e.preventDefault()
}

// end of prevent extra char for NUMBER input

export const handleOptionBtn = (item, setEditState, setMenuState, editID: number) => {
  setEditState(item.id)
  if (editID === item.id) {
    setMenuState((prev) => !prev)
    return
  }
  setMenuState(true)
}

export const convertMinsToHrsMins = (mins: number, shiftStart?: number, midnight?: boolean) => {
  if (shiftStart) {
    if (shiftStart + mins > 1440) {
      mins = mins - (1440 - shiftStart)
    } else {
      mins = shiftStart + mins
    }
  }

  let h: string | number = Math.floor(mins / 60)
  let m: string | number = mins % 60
  h = h < 10 ? '0' + h : h // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? '0' + m : m // (or alternatively) m = String(m).padStart(2, '0')
  return `${midnight ? (h == 24 ? '00' : h) : h}:${m}`
}

export const addZero = (num: string | number) => {
  if (Number(num) <= 9) {
    return '0' + num.toString()
  }
  return num
}

export const convertToDate = (param: any) => {
  const date = new Date(param)

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}-${addZero(month)}-${addZero(day)}`
}

export const formatDateForDisplay = (dt: string, monthName?: boolean) => {
  const date = new Date(dt)
  const day = date.getDate()
  let month: any = date.getMonth()
  const year = date.getFullYear()
  if (monthName) {
    month = months[month].substring(0, 3)
  } else {
    month = month + 1
  }
  return day + '. ' + month + '. ' + year + '.'
}

export const sortList = (data, param) => {
  const newArray = data && [...data]
  newArray.sort((a, b) => a[param].localeCompare(b[param]))
  return newArray
}

export const sortNestedList = (data, param1, param2) => {
  const newArray = data && [...data]
  newArray.sort((a, b) => a[param1][param2].localeCompare(b[param1][param2]))
  return newArray
}

export const convertFuelType = (type: number) => {
  switch (type) {
    case 1:
      return 'Petrol'
    case 2:
      return 'Diesel'
    case 3:
      return 'Electricity'
  }
}

export const isEmpty = (obj: any) => Object.keys(obj).filter((o) => o !== 'name').length === 0
export const isEmptyArray = (obj: any) => !Array.isArray(obj) || obj.length < 1

export const formatDate = (value: Date) => {
  const date = new Date(value.setDate(value.getDate() + 1)).toISOString()
  return date
}
export const convertAbsence = (type: number) => {
  switch (type) {
    case 1:
      return 'Day Off'
    case 2:
      return 'Vacation'
    case 3:
      return 'Maternity Leave'
    case 4:
      return 'Sick'
  }
}

export const removeDuplicates = (arr: any, id?: boolean, customSelectedProp?: string) => {
  const uniq = {}

  return !id
    ? arr?.filter((obj) => !uniq[obj.value] && (uniq[obj.value] = true))
    : arr?.filter(
        (obj) => !uniq[customSelectedProp || obj.id] && (uniq[customSelectedProp || obj.id] = true),
      )
}

export const handleErrors = (payload) => {
  const nestedMsg = payload.response.data.message

  if (!payload.response) {
    toast.error(payload)
    return
  }

  if (Array.isArray(nestedMsg)) {
    return nestedMsg.map((i) => toast.error(i))
  }
  if (nestedMsg.includes('referenced')) {
    toast.error('This item is being used somewhere else in the application.')
  } else if (nestedMsg.includes('(name)') || nestedMsg.includes('areaName')) {
    toast.error('Item with that name already exists.')
  } else {
    toast.error(nestedMsg)
  }
}

export const handleQuerySubmit = (
  e: React.FormEvent,
  setParams: React.Dispatch<any>,
  limit?: number,
  searchFields?: string[],
) => {
  e.preventDefault()
  setParams((prev: any) => {
    const copy = { ...prev }
    copy.q = e.target[1].value
    copy.page = 1
    copy.limit = limit ? limit : 30
    if (searchFields) {
      copy.searchFields = searchFields
    }
    return copy
  })
}

export const handlePagination = (page: number, setParams: React.Dispatch<any>, limit?: number) => {
  setParams((prev: any) => {
    const copy = { ...prev }
    copy.page = page
    copy.limit = limit ? limit : 30

    if (copy.dateFrom) {
      copy.dateFrom = convertToDate(new Date(copy.dateFrom))
    }

    if (copy.dateTo) {
      copy.dateTo = convertToDate(new Date(copy.dateTo))
    }
    return copy
  })
}

export const checkInvalid = (invalidFields: string[], name: string) => {
  return invalidFields.includes(name) ? 'true' : ''
}

export const handleStopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation()
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const handleFocusNext = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    event.preventDefault()

    // CALENDAR
    if (event.target.className.includes('date-picker')) {
      const calendar = event.target.parentNode.parentNode.parentNode

      //  Check if next element is input
      if (calendar.nextSibling.children[1].children[0].tagName === 'INPUT') {
        calendar.nextSibling.children[1].children[0].focus()
      }

      //  Check if next element is select
      if (calendar.nextSibling.children[1].className.includes('honest-select')) {
        calendar.nextSibling.children[1].focus()
      }
    }

    // SELECT
    if (event.target.className === 'honest-select-container') {
      //  Check if next element is calendar
      if (
        event.target.parentNode.nextSibling.children[1].className === 'react-datepicker-wrapper'
      ) {
        event.target.parentNode.nextSibling.children[1].children[0].children[1].focus()
      }
      //  Check if next element is input

      if (event.target.parentNode.nextSibling.children[1].children[0].tagName === 'INPUT') {
        event.target.parentNode.nextSibling.children[1].children[0].focus()
      }
      //  if next element is select
      event.target.parentNode.nextSibling.children[1].focus()
    }

    // INPUT
    if (event.target.tagName === 'INPUT') {
      const currentlyFocused = event.target.parentNode.parentNode

      // Check if next element is date picker
      if (currentlyFocused.nextSibling?.children[1].className === 'react-datepicker-wrapper') {
        currentlyFocused.nextSibling.children[1].children[0].children[1].focus()
      }

      // Check if the next element is select
      if (currentlyFocused.nextSibling?.children[1]?.className.includes('honest-select')) {
        currentlyFocused.nextSibling.children[1].focus()
        // Check if the next element is input
      } else if (currentlyFocused.nextSibling?.children[0].tagName === 'LABEL') {
        currentlyFocused.nextSibling.children[1].children[0].focus()
      }
    }
  }
}

export const convertArrayParams = (params: any) => {
  for (const p of Object.entries(params)) {
    if (p[0].includes('Ids') && typeof p[1] === 'string' && p[1] !== '') {
      params[String(p[0])] = p[1].split(',').map((f) => f.split('_')[0])
    } else if (p[0].includes('Ids') && p[1] === '') {
      params[p[0]] = []
    }
  }
  return params
}

export const formatParams = (
  params: any,
  forInitialFetch?: boolean,
  localeDateString?: boolean,
) => {
  for (const p in params) {
    if (typeof params[p] === 'string' && !(params[p].length > 0)) {
      delete params[p]
    } else if (Array.isArray(params[p]) && !(params[p].length > 0)) {
      delete params[p]
    } else if (params[p] == null || params[p] == 'null') {
      delete params[p]
    }

    if (p.includes('date')) {
      if (localeDateString) {
        params[p] = convertToDate(new Date(params[p]))
      } else {
        params[p] = new Date(params[p]).toISOString()
      }
    }

    if (!forInitialFetch) {
      if (Array.isArray(params[p])) {
        params[p] = params[p].map((f) => f.split('_')[0])
      } else if (typeof params[p] == 'string' && p.includes('Ids')) {
        params[p] = params[p].split(',').map((f) => f.split('_')[0])
      }
    }
  }
  return params
}

export const setState = (
  set: React.Dispatch<React.SetStateAction<any>>,
  property: string,
  newState: any,
) => {
  set((prev: any) => {
    const copy = structuredClone(prev)
    copy[property] = newState
    return copy
  })
}

export const millisecondsToHMS = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  seconds %= 60
  minutes %= 60

  return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds
    .toString()
    .padStart(2, '0')}s`
}

export const formatTimeAmPm = (minutes: number) => {
  if (typeof minutes !== 'number' || minutes < 0) {
    return ''
  }

  const hours = Math.floor(minutes / 60)
  const remainderMinutes = minutes % 60

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = remainderMinutes < 10 ? `0${remainderMinutes}` : remainderMinutes

  const period = hours < 12 ? 'AM' : 'PM'

  return `${formattedHours}:${formattedMinutes}${period}`
}

export const getTimeFromDate = (date: string) => {
  const minutes = new Date(date).getMinutes()
  const hours = new Date(date).getHours()
  return addZero(hours) + ':' + addZero(minutes)
}

export const permissionsAndLinks = {
  dashboard: 'dashboard',
  globalView: 'global-view',
  shifts: 'shifts',
  employees: 'employees',
  machinery: 'machinery',
  inventory: 'inventory',
  locations: 'locations',
  departments: 'departments',
  reports: 'reports',
  finances: 'finances',
  zones: 'zones',
}

export const getTime = (minutes: number, round?: number) => {
  minutes = Math.abs(minutes)
  if (typeof minutes !== 'number' || isNaN(minutes) || minutes < 0) {
    return ''
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours === 0) {
    return `${remainingMinutes.toFixed(round ?? 0)}min`
  } else if (remainingMinutes === 0) {
    return `${hours}h`
  } else {
    return `${hours}h : ${remainingMinutes.toFixed(round ?? 0)}min`
  }
}

export function sortByCounterClockwiseOrderForPolygon(coordinates) {
  // Calculate the centroid (average) of the coordinates
  const centroid = coordinates.reduce(
    (acc, coord) => {
      acc.lat += coord.lat
      acc.lng += coord.lng
      return acc
    },
    { lat: 0, lng: 0 },
  )
  centroid.lat /= coordinates.length
  centroid.lng /= coordinates.length

  // Calculate the angle of each point with respect to the centroid
  const angles = coordinates.map((coord) => {
    return {
      coord,
      angle: Math.atan2(coord.lat - centroid.lat, coord.lng - centroid.lng),
    }
  })

  // Sort the coordinates based on the angles in counter-clockwise order
  angles.sort((a, b) => a.angle - b.angle)

  // Extract the sorted coordinates
  const sortedCoordinates = angles.map((angle) => angle.coord)

  // Check if the sorting direction is clockwise, and reverse if needed
  const isClockwise = isPolygonClockwise(sortedCoordinates)
  if (isClockwise) {
    sortedCoordinates.reverse()
  }

  return sortedCoordinates
}

// Function to check if a polygon is oriented clockwise
function isPolygonClockwise(coordinates) {
  let sum = 0
  for (let i = 0; i < coordinates.length; i++) {
    const current = coordinates[i]
    const next = coordinates[(i + 1) % coordinates.length]
    sum += (next.lat - current.lat) * (next.lng + current.lng)
  }
  return sum > 0
}

export const debounce = (func: any, delay: number) => {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export const removeNullProperties = (obj) => {
  for (const prop in obj) {
    if (obj[prop] === null || obj[prop] == 'null') {
      delete obj[prop]
    }
  }
  return obj
}

export const getParams = ({
  location,
  ap,
  limit,
  localeDateString,
}: {
  location: Omit<Location, 'state' | 'key'>
  /** Additional Parametrs */
  ap?: any
  limit?: number
  localeDateString?: boolean
}) => {
  const params = removeNullProperties(Object.fromEntries(new URLSearchParams(location.search)))
  return isEmpty(params)
    ? { page: 1, limit: limit ?? 30, ...ap, ...params }
    : formatParams({ ...params, ...ap }, true, localeDateString)
}

export const areDatesSameDay = (date1: string | Date, date2: string | Date) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  if (
    d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() == d2.getFullYear()
  ) {
    return true
  }

  return false
}

export const convertToValidDate = (date: string) => {
  const splitted = date.split('/')

  return splitted[2] + '-' + splitted[0] + '-' + splitted[1]
}

export const setTimeTo = (date: Date, type: 'start' | 'end') => {
  const dt = new Date(date)
  dt.setHours(type === 'start' ? 0 : 23)
  dt.setMinutes(type === 'start' ? 0 : 59)
  dt.setSeconds(type === 'start' ? 0 : 59)
  dt.setMilliseconds(type === 'start' ? 0 : 59)
  return dt
}

export const groputDashboardItems = (group) => {
  const groupedItems = {}
  if (group) {
    for (const item of group) {
      let { name } = item
      const { count } = item
      if (!name) {
        name = taskStatusesData.find((ts) => ts.id == item.status).name
      }

      if (!groupedItems[name]) {
        groupedItems[name] = 0
      }

      groupedItems[name] += count
    }
  }

  return Object.entries(groupedItems).map(([name, value]) => ({ name, value }))
}

export const getAmPm = (date: Date) => {
  let hours = date.getHours()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  return hours + ' ' + ampm
}
