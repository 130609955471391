import React from 'react'
import { useDrop } from 'react-dnd'

import { IWorkingFunctions, IDropComponent, ITaskTemplateStateComponent } from 'types/task-templates.interface'

export const SingleOutput = ({ name, id, setState, state, noDrag, allFunctions }: ISingleOutput) => {
  const handleDrop = (item: IDropComponent) => {
    setState(prev => {
      const copy = structuredClone(prev)
      const components: ITaskTemplateStateComponent[] = copy.components
      const index = components.findIndex(c => c.id == Number((id as string).split('out')[1]))
      const foundFetchedFunction = (allFunctions as IWorkingFunctions[]).find(l => l.fid == item.functionId)
      if (copy.components[index].functionId) { // If calculated component alerady connected to a function - end function
        return copy
      }
      copy.components[index].functionId = item.functionId
      copy.components[index].functionResultIndex = foundFetchedFunction.outputs.find(o => o.component == item.component).order
      copy.components[index].inputParameters = []
      const hasfilledInputs = foundFetchedFunction.input.some(i => i.tempConnectedInput)
      if (hasfilledInputs) {
        const filledInputs = foundFetchedFunction.input.filter(i => i.tempConnectedInput)
        filledInputs.forEach(fc => copy.components[index].inputParameters.push({ type: 1, value: fc.tempConnectedInput.componentId }))
      }
      return copy
    })
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'funOutput',
      drop: handleDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [state, allFunctions],
  )

  return (
    <div
      style={{ background: isOver ? '#dadada' : '#ffffff' }}
      ref={noDrag ? null : drop}
      id={id as string}
      className='single-output'
    >
      {name}
    </div>
  )
}

interface ISingleOutput {
  name: string
  id: string | number
  setState?: any
  state?: any
  noDrag?: boolean
  allFunctions?: IWorkingFunctions[]
}