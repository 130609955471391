import React from 'react'
import {
  Animation,
  Flex,
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { TriggersCollapseItem } from './IssueTriggersCollapseItem'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IIssueTriggersTab } from 'types/issue-triggers.interface'

export const IssueTriggersTasksTab = ({ state, setState, handleSubmit }: IIssueTriggersTab) => {
  const handleAdd = (item: any) => {
    setState((prev) => {
      const copy = { ...prev }
      const exists = copy.parentTaskTemplates.find((pt) => pt.id === item.id)
      if (exists) {
        copy.parentTaskTemplates = copy.parentTaskTemplates.filter((i) => i.id !== item.id)
      } else {
        copy.parentTaskTemplates.push(item)
      }
      return copy
    })
  }

  return (
    <Animation>
      <Flex between gap='2rem'>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Task Templates</Title>
          {state.parentTaskTemplates
            ?.filter((pt) => state.parentTaskTemplates.some((i) => pt.id == i.id))
            .map((item) => {
              return (
                <TriggersCollapseItem
                  prop='parentTaskTemplates'
                  setState={setState}
                  key={item.id}
                  data={item}
                />
              )
            })}
        </SelectedItemsContainer>
        <ItemsPick
          selectedList={state.parentTaskTemplates}
          handleAdd={handleAdd}
          link='task-template/overviews'
          placeholder='search task templates'
        />
      </Flex>
      <TabFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}
