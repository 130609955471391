import { issuesIcon } from './../assets/icons/IssuesIcon'
import { locationsIcon } from 'assets/icons/LocationsIcon'
import { SuitcaseIcon } from 'assets/icons/SuitcaseIcon'
import { zonesIcon } from 'assets/icons/ZonesIcon'
import { employeesIcon } from '../assets/icons/EmployeesIcon'
import { FolderIcon } from '../assets/icons/FolderIcon'
import { inventoryIcon } from '../assets/icons/InventoryIcon'
import { machineryIcon } from '../assets/icons/MachineryIcon'
import { NutIcon } from '../assets/icons/NutIcon'
import { shiftsIcon } from '../assets/icons/ShiftsIcon'
import { TasksIcon } from '../assets/icons/TasksIcon'
import { IndustriesIcon } from 'assets/icons/IndustriesIcon'
import { CompaniesIcon } from 'assets/icons/CompaniesIcon'
import { SettingsIcon } from 'assets/icons/SettingsIcons'
import { arrowsIcon } from 'assets/icons/Arrows'
import { financesIcon } from 'assets/icons/financesIcon'
import { WorkflowIcon } from 'assets/icons/WorkflowIcon'
import { FileIcon } from 'assets/icons/FileIcon'
import { FoldMapIcon } from 'assets/icons/FoldMapIcon'
import { DashboardIcon } from 'assets/icons/DashboardIcon'
import { roleIcon } from 'assets/icons/RoleIcon'
import { ProcessTemplateIcon } from 'assets/icons/ProcessTemplateIcon'
import { CycleIcon } from 'assets/icons/CycleIcon'

export const linksClient: ILink[] = [
  {
    path: '/dashboard',
    id: 1111,
    text: 'Dashboard',
    icon: DashboardIcon,
    abr: 'dashboard',
  },
  {
    path: '/global-view',
    id: 1,
    text: 'Global View',
    icon: FoldMapIcon,
    abr: 'globalView',
  },
  {
    path: '/employees',
    id: 2,
    text: 'employees',
    icon: employeesIcon,
    abr: 'employees',
  },
  {
    path: '/roles',
    id: 3,
    text: 'roles',
    icon: roleIcon,
    abr: 'roles',
  },
  {
    path: '/shifts',
    id: 4,
    text: 'shifts',
    icon: shiftsIcon,
    abr: 'shifts',
  },

  {
    path: '/machinery',
    id: 5,
    text: 'machinery',
    icon: machineryIcon,
    abr: 'machinery',
  },
  {
    path: '/inventory-items',
    id: 6,
    text: 'Inventory',
    list: [
      { name: 'Items', path: '/inventory-items' },
      { name: 'Suppliers', path: '/inventory-supliers' },
    ],
    icon: inventoryIcon,
    abr: 'inventory',
  },
  {
    path: '/locations',
    id: 7,
    text: 'locations',
    icon: locationsIcon,
    abr: 'locations',
  },
  {
    id: 8,
    path: '/departments',
    text: 'Departments',
    icon: SuitcaseIcon,
    abr: 'departments',
  },
  {
    id: 9,
    path: '/tasks',
    text: 'Tasks',
    icon: TasksIcon,
    abr: 'inventory',
  },
  {
    id: 10,
    path: '/reports',
    text: 'Reports',
    icon: FileIcon,
    abr: 'reports',
  },
  {
    path: '/finances-costs',
    id: 11,
    text: 'Finances',
    icon: financesIcon,
    list: [{ name: 'Costs', path: '/finances-costs' }],
    abr: 'finances',
  },
  {
    path: '/zones',
    id: 12,
    text: 'zones',
    icon: zonesIcon,
    abr: 'zones',
  },
]

export const locations = [
  {
    id: 1,
    title: 'Luxembourg',
    address: '3, Place De Clairefontaine, L-1341 Luxembourg',
    phone: '(406) 555-0120',
    email: 'atfdubai@atf.com',
    coordinates: {
      lat: 49.61019,
      lng: 6.13283,
    },
  },
]

export const linksAdmin: ILink[] = [
  {
    id: 2,
    path: '/operations',
    text: 'Operations',
    icon: NutIcon,
  },
  {
    id: 3,
    path: '/resources-models',
    text: 'Resources',
    list: [
      { name: 'Models', path: '/resources-models' },
      { name: 'Fields', path: '/resources-fields' },
      { name: 'Categories', path: '/resources-categories' },
      { name: 'Role Templates', path: '/resources-role-templates' },
    ],
    icon: FolderIcon,
  },
  {
    id: 4,
    path: '/issues',
    text: 'Issues',
    icon: issuesIcon,
  },
  {
    id: 5,
    path: '/companies',
    text: 'Companies',
    icon: CompaniesIcon,
  },

  {
    id: 6,
    path: '/inventory-fields',
    text: 'Inventory Settings',
    icon: SettingsIcon,
    list: [
      { name: 'Fields', path: '/inventory-fields' },
      { name: 'Categories', path: '/inventory-categories' },
    ],
  },
  {
    id: 7,
    path: '/industries',
    text: 'Industries',
    icon: IndustriesIcon,
  },
  {
    id: 8,
    path: '/task-templates',
    text: 'Task Templates',
    icon: TasksIcon,
    list: [
      { name: 'Task Templates', path: '/task-templates' },
      { name: 'Worker Components', path: '/tasks-worker-components' },
    ],
  },
  {
    id: 8,
    path: '/cycles',
    text: 'Task cycles',
    icon: CycleIcon,
  },
  {
    id: 10,
    path: '/workflows',
    text: 'Workflows',
    icon: WorkflowIcon,
  },
  {
    id: 11,
    path: '/process-templates',
    text: 'Process Templates',
    icon: ProcessTemplateIcon,
  },

  {
    id: 12,
    path: '/triggers-issue',
    text: 'Triggers',
    icon: arrowsIcon,
    list: [
      { name: 'Issue Triggers', path: '/triggers-issue' },
      { name: 'Task Triggers', path: '/triggers-task' },
    ],
  },
]

export interface ILink {
  id: number
  path: string
  text: string
  abr?: string
  icon: JSX.Element
  list?: {
    name: string
    path: string
  }[]
}
