/*eslint-disable*/
import { CancelBtn } from 'components/Button/CancelBtn'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { Spinner } from 'components/Loading/Loading'
import { NavBar } from 'components/NavBar/NavBar'
import { ISelectValue } from 'constants/globalTypes'
import { clearGenerated, confirmTask, confirmWorkflow, generateTask } from 'features/tasks/tasksSlice'
import { clearInputComponents, getInputComponents } from 'features/taskTemplates/taskTemplatesSlice'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import {
    Animation,
    Button,
    ButtonsContainer,
    ContentWrapper,
    Flex,
    H1WithBack,
    ItemsPickContainer,
    PageFooter,
    PageWrapper,
    SelectedItemsContainer,
    Separator,
    Title,
} from 'styling/GlobalStyles'
import { IDepartmentForTask, IDepartmentForTaskWithTypes } from 'types/api/tasksApi.interface'
import { ITaskInputComponentsResponse } from 'types/api/tasksTemplatesApi.interface'
import { IAddTaskState } from 'types/tasks.interface'
import { setState } from 'utils/helpers'
import { TaskSection } from './sections/TaskSection'
import { getSingleWorkflowTemplateForCompany } from 'features/workflows/workflowsSlice'
import { WorkflowSection } from './sections/WorkflowSection'
import { IWorkflowTemplateForCompanyResponse } from 'types/api/workflowsAPi.interface'

export const AddTask = () => {
    const [department, setDepartment] = useState<IDepartmentForTaskWithTypes>(null)
    const [config, setConfig] = useState<IAddTaskState>({
        task: null,
        location: null,
        machine: null,
        item: null,
        date: new Date(),
        criteria: null,
        components: [],
        items: [],
    })
    const [locations, setLocations] = useState<ISelectValue[]>([])
    const configRef = useRef(null)
    const dispatch = useDispatch<AppDispatch>()
    const { isLoadingGenerate, generatedTask, generatedWorkflow } = useSelector((state: RootState) => state.tasks)
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatch(clearInputComponents())
            dispatch(clearGenerated())
        }
    }, [])

    const handleAdd = (e: IDepartmentForTask) => {
        dispatch(clearInputComponents())
        dispatch(clearGenerated())
        setConfig({
            task: null,
            location: null,
            machine: null,
            item: null,
            date: new Date(),
            criteria: null,
            components: [],
            items: [],
        })
        setDepartment({
            ...e,
            workflowTemplates: e.workflowTemplates.map((wf) => ({ ...wf, type: 'workflow' })),
            processTemplates: e.processTemplates.map((p) => ({ ...p, type: 'process' })),
            tasks: e.tasks.map((t) => ({ ...t, type: 'task' })),
            taskCycleTemplates: e.taskCycleTemplates.map((c) => ({ ...c, type: 'cycle' })),
        })
        setLocations(e.locations.map((l) => ({ label: l.name, value: l.id })))
    }

    const handleChange = (value: ISelectValue, name: string) => {
        if (name == 'task') {
            dispatch(clearInputComponents())
            // TODO Napraviti dobar interfejs za string u value selecta
            //@ts-ignore
            const s = (value.value as string).split('-')
            if (s[1] == 'task') {
                getTaskTemplateComponents(s[0])
            } else if (s[1] == 'workflow') {
                getWorkflowDetails(s[0])
            }
            const v = { label: value.label, value: Number(s[0]), type: s[1] }
            setState(setConfig, name, v)
        } else {
            setState(setConfig, name, value)
        }
    }

    const getTaskTemplateComponents = async (id: string) => {
        const resp = await dispatch(getInputComponents(id))
        setConfig((prev) => ({
            ...prev,
            components: (resp.payload as ITaskInputComponentsResponse).taskTemplate?.components.map(
                (c) => ({ name: c.name, value: null, id: c.id }),
            ),
        }))
    }

    const getWorkflowDetails = async (id: string) => {
        const resp = await dispatch(getSingleWorkflowTemplateForCompany(id))
        setConfig((prev) => ({
            ...prev,
            items: (resp.payload as IWorkflowTemplateForCompanyResponse)?.items?.map((i) => {
                const machine = i.taskTemplate.toolTypeComponents.find(c => c.toolTypeComponent.name == 'Machinery')
                const item = i.taskTemplate.toolTypeComponents.find(c => c.toolTypeComponent.name == 'Item')
                return {
                    workflowTemplateItemId: i.id,
                    location: prev.location,
                    components: i.taskTemplate.components.map((c) => ({
                        name: c.name,
                        id: c.id,
                        value: null,
                        componentType: c.componentType,
                    })),
                    machine: !machine ? null : { label: machine.name, value: null, taskTemplateToolTypeComponentId: machine.id },
                    item: !item ? null : { label: item.name, value: null, taskTemplateToolTypeComponentId: item.id, amount: null },
                    name: i.taskTemplate.name,
                    id: i.id
                }
            }),
            // components: (resp.payload as IWorkflowTemplateForCompanyResponse).taskTemplate?.components.map(
            //     (c) => ({ name: c.name, value: null, id: c.id }),
            // ),
        }))
    }

    const parseToolComponents = (r) => {
        return r.data.map((m) => ({ value: m.id, label: m.name }))
    }


    const handleConfirm = async () => {
        if (config.task.type == 'task') {
            await dispatch(confirmTask(generatedTask.task.id))
            navigate('/dashboard/tasks/' + generatedTask.task.id + '?fromTasks=true')
        } else {
            await dispatch(confirmWorkflow(generatedWorkflow.id))
            navigate('/dashboard/tasks/' + generatedWorkflow.items[0].task.id + '?fromTasks=true')
        }

    }


    return (
        <PageWrapper>
            <NavBar />
            <H1WithBack arrow title='Add Task' />
            <Separator />
            <ContentWrapper>
                <Spinner inElement={true} loading={isLoadingGenerate} />
                <Animation>
                    <ItemsPickContainer>
                        <SelectedItemsContainer ref={configRef}>
                            <Title pad='0'>Configure Task</Title>
                            {department && (
                                <Flex column gap='2'>
                                    <Input
                                        labelText='Start date'
                                        type='datetime-local'
                                        name='date'
                                        onChange={(v) => setState(setConfig, 'date', new Date(v.target.value))}
                                        value={config.date.toISOString().slice(0, 16)}
                                    />
                                    <HonestSelectInput
                                        labelText='Task'
                                        options={[...department.tasks, ...department.workflowTemplates].map((l) => ({
                                            label: l.name,
                                            value: l.id.toString() + '-' + l.type,
                                        }))}
                                        defaultValue={config.task}
                                        onChange={handleChange}
                                        name='task'
                                        reset={department}
                                    />
                                    {config.task?.type == 'task' && (
                                        <TaskSection
                                            handleChange={handleChange}
                                            config={config}
                                            setConfig={setConfig}
                                            configRef={configRef}
                                            department={department}
                                            parseToolComponents={parseToolComponents}
                                        />
                                    )}
                                    {config.task?.type == 'workflow' && (
                                        <WorkflowSection
                                            setConfig={setConfig}
                                            locations={locations}
                                            handleChange={handleChange}
                                            config={config}
                                            configRef={configRef}
                                            departmentId={department.id}
                                            parseToolComponents={parseToolComponents}
                                        />
                                    )}
                                </Flex>
                            )}
                        </SelectedItemsContainer>
                        <ItemsPick
                            handleAdd={handleAdd}
                            link='departments/mobile'
                            selectedList={[department]}
                            placeholder='Search departments'
                        />
                    </ItemsPickContainer>
                </Animation>
            </ContentWrapper>
            <PageFooter>
                <ButtonsContainer>
                    <CancelBtn />
                    {((config.task?.type == 'task' && generatedTask) || (config.task?.type == 'workflow' && generatedWorkflow)) && (
                        <Button orange onClick={handleConfirm}>
                            Confirm {config.task?.type}
                        </Button>
                    )}
                </ButtonsContainer>
            </PageFooter>
        </PageWrapper>
    )
}
