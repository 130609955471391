import React, { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import './styles.css'
import Xarrow, { Xwrapper } from 'react-xarrows'
import { v4 } from 'uuid'
import { TabFooter, Button, Flex } from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import {
  WorkflowColumn,
  WorkflowColumnsContainer,
  WorkflowLogicContainer,
} from './Workflows.styled'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SingleWorkflowTask } from './Components/SingleWorkflowTask'
import { DropMainTaskWorkflow } from './Components/DropMainTaskWorkflow'
import { ClearButton } from 'components/Button/ClearButton'
import { IWorkflowTab } from 'types/workflows.interface'
import { ToggleButton } from 'components/Input/ToggleButton'

export const WorkflowLocicTab = ({ state, setState, handleSubmit }: IWorkflowTab) => {

  const [linkType, setLinkType] = useState<1 | 2>(1)

  const handleClearLogic = () => {
    setState((prev) => {
      const copy = { ...prev }
      copy.items = []
      return copy
    })
  }
  const renderColums = () => {
    const columns = []

    const getTasks = (level: number) => {
      const levelTasks = state.items
        .filter((t) => t.level === level)
        .sort((a, b) => (a.level === b.level ? a.index - b.index : a.level - b.level))
      const modifiedArray = []

      levelTasks.forEach((t, k: number) => {
        const getUpwardMove = () => {
          const nextLevel = state.items.filter((t) => t.level === level + 1)
          return nextLevel.filter((t) =>
            t.parentItems.some((i) => i.index == k - 1 && i.level == level),
          ).length
        }

        modifiedArray[t.index] = (
          <SingleWorkflowTask
            linkType={linkType}
            move={t.level < 1 ? 0 : getUpwardMove()}
            id={'level-' + level + '_index-' + t.index}
            state={state}
            setState={setState}
            task={t}
          />
        )
      })
      return [
        ...modifiedArray,
        level === 0 && <DropMainTaskWorkflow state={state} setState={setState} />,
      ]
    }
    for (
      let i = 0;
      i <=
      state.items.reduce((maxLevel, item) => {
        return item.level > maxLevel ? item.level : maxLevel
      }, 0);
      i++
    ) {
      columns.push(<WorkflowColumn id={'level-' + i}>{getTasks(i)}</WorkflowColumn>)
    }
    return columns
  }

  const getArrows = () => {
    const arrows = []
    for (const t of state.items) {
      if (t.parentItems.length > 0) {
        for (const p of t.parentItems) {
          arrows.push({
            start: 'level-' + p.level + '_index-' + p.index,
            end: 'level-' + t.level + '_index-' + t.index,
            dashed: p.linkType == 2
          })
        }
      }
    }
    return arrows
  }

  return (
    <>
      <Flex gap='1rem' height='90%'>
        <DndProvider backend={HTML5Backend}>
          <WorkflowLogicContainer>
            <div className='toggle' >
              <ToggleButton
                state={linkType == 2}
                handleChange={() => setLinkType(linkType < 2 ? 2 : 1)}
                maxWidth
                label='Optional links'
              />
            </div>
            <Xwrapper>
              {getArrows().map((item) => {
                return (
                  <Xarrow
                    key={v4()}
                    start={item.start}
                    end={item.end}
                    color={'#ff9900'}
                    strokeWidth={2}
                    headSize={0}
                    dashness={item.dashed ? { strokeLen: 5, nonStrokeLen: 3, animation: -2 } : false}
                    path='smooth'
                    endAnchor='left'
                    startAnchor='right'
                  />
                )
              })}
              <WorkflowColumnsContainer>{renderColums()}</WorkflowColumnsContainer>
            </Xwrapper>
          </WorkflowLogicContainer>
          <ItemsPick
            link='task-template/overviews'
            width='30%'
            selectedList={state.items.map((i) => ({ ...i, id: i.taskTemplateId }))}
            oneColumn
            extraParams={{ isLogicAdded: true }}
            placeholder='Search Tasks'
            drag
          />
        </DndProvider>
      </Flex>

      {handleSubmit && (
        <TabFooter>
          <Flex width='100%' end='true' gap='1rem'>
            <ClearButton text='Clear logic' onClick={handleClearLogic} />
            <CancelBtn />
            <Button height='40' onClick={handleSubmit} orange>
              Save
            </Button>
          </Flex>
        </TabFooter>
      )}
    </>
  )
}
