import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { ISelectValue } from 'constants/globalTypes'
import { Button, Flex, H2, Separator } from 'styling/GlobalStyles'
import { ICycleState } from 'types/cycles.interface'
import { components } from '../TaskTemplates/taskData'

export const SingleComponent = ({ index, state, setState, view }: { index: number, view?: boolean, setState: React.Dispatch<React.SetStateAction<ICycleState>>, state: ICycleState }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.components[index].name = e.target.value
            return copy
        })
    }

    const getDefaultValue = () => {
        return state.components[index].component
    }

    const handleAddStep = () => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.components.push({
                name: '',
                component: null,
            })
            return copy
        })
    }

    const handleSelect = (value: ISelectValue) => {

        setState(prev => {
            const copy = structuredClone(prev)
            copy.components[index].component = value.value
            return copy
        })

    }

    return <Flex style={{ pointerEvents: view ? 'none' : 'all' }} gap='1rem' width='15rem' column>
        <H2>Component: {index + 1}</H2>
        <Input
            type='text'
            value={state?.components[index]?.name}
            onChange={handleChange}
            placeholder='Enter component name'
            labelText='Component name'
            invalid={state?.components[index]?.name?.length < 2 ? 'true' : ''}
            nomarg
        />

        <HonestSelectInput
            name='component'
            onChange={(value: ISelectValue) => handleSelect(value)}
            labelText='Component type'
            defaultValue={getDefaultValue()}
            options={components.map(c => ({ label: c.name, value: c.component }))}
            nomarg
        // invalid={invalidCategoryFields.includes(field.name)}
        />
        <Separator />
        {state.components.length - 1 == index && !view && <Button orange onClick={handleAddStep}>Add Component</Button>}

    </Flex>
}