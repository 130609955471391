import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from 'utils/axios'
import { handleErrors } from 'utils/helpers'

interface FinancesInitalState {
  isLoading: boolean
  overview: {
    overview: {
      tasksCount: number
      estimatedCost: number
      actualCost: number
    }
    overviewByDepartments: IOverviewByDepartments[]
  }
  isLoadingAuth: boolean
}

export interface IOverviewByDepartments {
  departmentName: string
  tasksCount: number
  estimatedCost: number
  actualCost: number
}

interface IOverviewParams {
  startDate: string
  endDate: string
}

const initialState: FinancesInitalState = {
  isLoading: false,
  overview: null,
  isLoadingAuth: false,
}

export const getOverview = createAsyncThunk(
  'finances/getOverview',
  async (params: IOverviewParams, thunkAPI) => {
    try {
      const resp = await baseUrl.get('finance-cost/overview', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const financesSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {},
  extraReducers: {
    [getOverview.pending.type]: (state) => {
      state.isLoading = true
    },
    [getOverview.fulfilled.type]: (state, { payload }) => {
      state.overview = payload
      state.isLoading = false
    },
    [getOverview.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default financesSlice.reducer
