/*eslint-disable*/
import { NavBar } from 'components/NavBar/NavBar'
import { IDBMachineUserTask } from 'features/dashboard/types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { Animation, Button, ColoredDot, Flex, H1, H1WithBack, HR, NotAvailable, NotAvailableContainer, P, PageWrapper, Separator } from 'styling/GlobalStyles'
import { DBTaskStatus, DBTaskTimelineItem, DBTimelineTitle } from '../DashboardTasks/DBTasks.styled'
import { ImagesSlider } from 'components/ImagesSlider/ImagesSlider'
import { getSingleDBMachine } from 'features/dashboard/dbMachinesSlice'
import { DBMachineDetail, DBMachineFuelLevelContainer, DBMachineFuelLevelIndicator, DBMachineGraphBox, DBMachineInfoSection, DBMachineMap, DBMachineRightSide, DBMachineStatusIcon } from './DBMachines.styled'
import { machineryStatusesData } from '../dashboardData'
import { formatDate, formatDateForDisplay, getTime } from 'utils/helpers'
import { TasksTabs, TasksTabsContainer } from 'pages/TaskTemplates/DragComponents/Tasks.styled'
import { Chart } from 'components/Charts/Charts'
import Chevron from 'assets/icons/Chevron'
import { Wrapper } from '@googlemaps/react-wrapper'
import { PowerIcon } from 'assets/icons/PowerIcon'
import { Modal } from 'components/Modal/Modal'
import { TasksModal } from './TasksModal'
import machineMapIcon from '../../../assets/images/machineMapIcon.png'

interface ITimelineItem {
    data: IDBMachineUserTask
    margin?: boolean
    openDefault?: boolean
}

const Utilization = ({ notAvailable }: { notAvailable: boolean }) => {
    const [open, setOpen] = useState(true)
    const [activeTab, setActiveTab] = useState('Today')
    const fuelData = useSelector((state: RootState) => state.dbMachines?.singleDBMachine?.develonVehicle)?.fuelData
    const utilization = useSelector((state: RootState) => state.dbMachines?.singleDBMachine?.develonVehicle)?.utilization
    const dispatch = useDispatch<AppDispatch>()
    const { id } = useParams()
    useEffect(() => {
        if (id) {
            dispatch(getSingleDBMachine({ id, utilizationInfoDateSpanType: activeTab == 'Today' ? 1 : activeTab == '1 Week' ? 2 : 3 }))
        }
    }, [id, activeTab])

    return (
        <DBMachineInfoSection open={open}>
            {notAvailable && <NotAvailable style={{ borderRadius: '12.5px' }}>
                <P>Not Available</P>
            </NotAvailable>}
            <Flex column gap='8px' width='100%' alignStart>
                <Flex width='100%' between center onClick={() => setOpen(p => !p)} >
                    <h4>Utilization</h4>
                    <Chevron open={open} />
                </Flex>
                {open && <Flex column width='100%' >
                    <Flex m='1rem 0' width='100%' between >
                        <TasksTabsContainer noMargin >
                            <TasksTabs adaptWidth active={activeTab == 'Today'} onClick={() => setActiveTab('Today')} >
                                Today
                            </TasksTabs>
                            <TasksTabs adaptWidth active={activeTab == '1 Week'} onClick={() => setActiveTab('1 Week')} >
                                1 Week
                            </TasksTabs>
                            <TasksTabs adaptWidth active={activeTab == '2 Weeks'} onClick={() => setActiveTab('2 Weeks')} >
                                2 Weeks
                            </TasksTabs>
                        </TasksTabsContainer>
                        {/* <Flex gap='1rem' center>
                            <P>05.05.2020.</P>
                            <BoxDateInput label='' date={new Date()} />
                        </Flex> */}
                    </Flex>
                    <Flex width='100%'>
                        <Flex width='100%' gap='2rem'  >
                            <DBMachineGraphBox>
                                <Flex width='100%' between >
                                    <h5>Operation Hour</h5>
                                    <h5>{utilization?.totalHours?.toFixed(2)} l</h5>
                                </Flex>
                                <HR mt='1' />
                                <P style={{ margin: '1rem 0' }} >Utilization Rate</P>
                                <Chart notAvailable={!utilization?.utilizationRatePercent} fontSize={12} customLabel={utilization?.utilizationRatePercent + '%'} color={'#00B04C'} data={[{ name: 'Working', value: notAvailable ? 5 : utilization?.operatingHours }, { name: 'Idle', value: notAvailable ? 23 : utilization.idleHours }]} chartColors={['#00B04C', '#DFDEE1']} modifiedData={[{ name: 'Working', value: notAvailable ? 5 : utilization?.operatingHours }, { name: 'Idle', value: notAvailable ? 23 : utilization.idleHours }]} />
                                <Flex width='100%' between >
                                    <Flex mt='1rem' gap='1rem' center >
                                        <ColoredDot color='#00B04C' />
                                        <P>Working</P>
                                    </Flex>
                                    <P>{utilization?.operatingHours ?? '/'} h</P>
                                </Flex>
                                <Flex mt='15px' width='100%' between >
                                    <Flex gap='1rem' center >
                                        <ColoredDot color='#DFDEE1' />
                                        <P>Idle</P>
                                    </Flex>
                                    <P>{utilization?.idleHours ?? '/'} h</P>
                                </Flex>
                            </DBMachineGraphBox>
                            <DBMachineGraphBox>
                                <Flex width='100%' between >
                                    <h5>Fuel Used</h5>
                                    <h5>{fuelData?.totalAmount} l</h5>
                                </Flex>
                                <HR mt='1' />
                                <P style={{ margin: '1rem 0' }} >Utilization Rate</P>
                                <Chart notAvailable={!fuelData?.amountPerHour} fontSize={12} customLabel={notAvailable ? '100%' : (fuelData?.amountPerHour + ' L/H')} color={'#ff9900'} data={[{ name: 'Working', value: notAvailable ? 5 : fuelData?.totalAmountForOperatingHours }, { name: 'Idle', value: notAvailable ? fuelData?.totalAmountForIdleHours : 23 }]} chartColors={['#FF9900', '#DFDEE1']} modifiedData={[{ name: 'Working', value: notAvailable ? 5 : fuelData?.totalAmountForOperatingHours }, { name: 'Idle', value: notAvailable ? fuelData?.totalAmountForIdleHours : 23 }]} />
                                <Flex width='100%' between >
                                    <Flex mt='1rem' gap='1rem' center >
                                        <ColoredDot color='#FF9900' />
                                        <P>Working</P>
                                    </Flex>
                                    <P>{fuelData?.totalAmountForOperatingHours ?? '/'} h</P>
                                </Flex>
                                <Flex mt='15px' width='100%' between >
                                    <Flex gap='1rem' center >
                                        <ColoredDot color='#DFDEE1' />
                                        <P>Idle</P>
                                    </Flex>
                                    <P>{fuelData?.totalAmountForIdleHours ?? '/'} h</P>
                                </Flex>
                            </DBMachineGraphBox>
                        </Flex>
                    </Flex>
                </Flex>}
            </Flex>
        </DBMachineInfoSection>
    )
}

const GeneralInfo = () => {
    const [open, setOpen] = useState(false)
    const { singleDBMachine } = useSelector((state: RootState) => state.dbMachines)

    return (
        <DBMachineInfoSection open={open}>
            <Flex column gap='8px' width='100%' alignStart>
                <Flex width='100%' between center onClick={() => setOpen(p => !p)} >
                    <h4>General Info</h4>
                    <Chevron open={open} />
                </Flex>
                {open && (
                    <Flex width='100%' column>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Machine Name</DBMachineDetail>
                            <DBMachineDetail width='70%'>{singleDBMachine?.name}</DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Category</DBMachineDetail>
                            <DBMachineDetail width='70%'>
                                {singleDBMachine?.category.name}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Model</DBMachineDetail>
                            <DBMachineDetail width='70%'>
                                {singleDBMachine?.model.model}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Task</DBMachineDetail>
                            <DBMachineDetail width='70%'>
                                {singleDBMachine?.currentTask?.name || '/'}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Purchase date</DBMachineDetail>
                            <DBMachineDetail width='70%'>
                                {singleDBMachine?.purchaseDate ? formatDate(new Date(singleDBMachine?.purchaseDate)) : '/'}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Price</DBMachineDetail>
                            <DBMachineDetail width='70%'>
                                {singleDBMachine?.price}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem'>
                            <DBMachineDetail bold width='30%'>Total working hours</DBMachineDetail>
                            <DBMachineDetail width='70%'>
                                {singleDBMachine?.develonVehicle ? singleDBMachine?.develonVehicle.totalWorkingHours : '/'}
                            </DBMachineDetail>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </DBMachineInfoSection>
    )
}

const TimelineItem = ({ data, margin, openDefault }: ITimelineItem) => {
    const [open, setOpen] = useState(openDefault)
    return (
        <DBTaskTimelineItem margin={margin} onClick={() => setOpen((prev) => !prev)}>
            <DBTimelineTitle status={1}>
                <Flex column gap='8px' width='100%' alignStart>
                    <Flex width='100%' between center>
                        <h3>{data?.name}</h3>
                        <p>{formatDateForDisplay(data?.date ?? '/')}</p>
                    </Flex>
                    {open && (
                        <>
                            <Separator />
                            <Flex width='100%' between>
                                <p>Location: </p>
                                <p>{data?.location?.name}</p>
                            </Flex>
                            <Flex width='100%' between>
                                <p>Actual Duration: </p>
                                <p>{data?.durationInMinutes ? getTime(data?.durationInMinutes) : '-'}</p>
                            </Flex>
                            <Flex width='100%' between>
                                <p>Employee: </p>
                                <p>{data?.user.firstName + ' ' + data?.user.lastName}</p>
                            </Flex>
                        </>
                    )}
                </Flex>
            </DBTimelineTitle>
        </DBTaskTimelineItem>
    )
}

export const DBSingleMachine = () => {
    const { id } = useParams()
    const [imagesModal, setImagesModal] = useState({ open: false, images: [] })
    const [tasksModal, setTasksModal] = useState({ open: false })
    const { singleDBMachine } = useSelector((state: RootState) => state.dbMachines)
    const mapRef = useRef(null)
    const [map, setMap] = useState<any>()

    useEffect(() => {
        if (map && singleDBMachine?.develonVehicle) {
            const content = document.createElement('div')
            const imageContent = document.createElement('img')
            const text = document.createElement('p')
            text.innerText = 'ds'
            content.className = 'marker'
            imageContent.className = 'marker-image'
            imageContent.src = machineMapIcon

            content.appendChild(imageContent)
            content.appendChild(text)

            const marker = new google.maps.marker.AdvancedMarkerElement({
                position: { lat: singleDBMachine.develonVehicle.latitude, lng: singleDBMachine.develonVehicle.longitude },
                map,
                content,
            })
        }

    }, [singleDBMachine])



    const mapOptions = {
        zoom: 2,
        mapId: '1ad8431b024d9e03',
        center: {
            lat: 0,
            lng: -0,
        },
        disableDefaultUI: true,
        mapTypeId: 'hybrid',
        styles: [
            {
                featureType: 'road',
                stylers: [
                    { visibility: 'off' }
                ]
            }
        ]
    }

    useEffect(() => {
        if (mapRef.current) {
            setMap(new window.google.maps.Map(mapRef.current, mapOptions))
        }
    }, [mapRef.current])


    const Status = () => {
        const status = machineryStatusesData.find((s) => s.id == singleDBMachine?.status)
        return (
            <DBTaskStatus status={singleDBMachine?.status}>
                <DBMachineStatusIcon color={status?.iconColor}>{status?.icon}</DBMachineStatusIcon>
                <Flex gap='5px'>
                    <p style={{ fontWeight: 'bold' }} >Status: </p>
                    <p>{status?.name}</p>
                </Flex>
            </DBTaskStatus>
        )
    }

    const Power = () => {
        return (
            <DBTaskStatus status={singleDBMachine?.status}>
                <DBMachineStatusIcon color={singleDBMachine?.develonVehicle?.isRunning ? "#00B04C" : '#ff8899'}><PowerIcon /></DBMachineStatusIcon>
                <Flex gap='5px'>
                    <p style={{ fontWeight: 'bold' }} >Engine: </p>
                    <p>{singleDBMachine?.develonVehicle ? (singleDBMachine.develonVehicle.isRunning ? 'On' : 'Off') : '/'}</p>
                </Flex>
            </DBTaskStatus>
        )
    }


    return (
        <>
            <NavBar />
            <Animation>
                <PageWrapper noFooter>
                    <Flex width='100%' between>
                        <H1WithBack arrow title={singleDBMachine?.name} />
                        <Flex gap='2rem' >
                            <Status />
                            <Power />
                        </Flex>
                    </Flex>
                    <Flex width='100%' gap='2rem' mt='30'>
                        <Flex column gap='1rem' width='60%'>
                            <Utilization notAvailable={!singleDBMachine?.develonVehicle} />
                            <GeneralInfo />
                        </Flex>
                        <DBMachineRightSide>
                            <Flex mb='10' width='100%' between >
                                <h4>Location</h4>
                                <h4 style={{ color: '#ff9900' }} >{singleDBMachine?.currentTask ? singleDBMachine.currentTask.location?.name : 'Not Available'}</h4>
                            </Flex>
                            <NotAvailableContainer style={{ borderRadius: 15 }}>
                                {!singleDBMachine?.develonVehicle && <NotAvailable><P style={{ color: "#fff" }} >Not Available</P></NotAvailable>}
                                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY} version='beta' libraries={['marker']}>
                                    <DBMachineMap ref={mapRef} />
                                </Wrapper>
                            </NotAvailableContainer>
                            <h4>Fuel Level</h4>
                            <NotAvailableContainer>
                                {!singleDBMachine?.develonVehicle && <NotAvailable><P>Not Available</P></NotAvailable>}
                                <DBMachineFuelLevelContainer>
                                    <DBMachineFuelLevelIndicator level={singleDBMachine?.develonVehicle?.fuelRemainingPercent ?? 60} />
                                    <H1 color='#ff9900' >{singleDBMachine?.develonVehicle?.fuelRemainingPercent ?? 60}%</H1>
                                </DBMachineFuelLevelContainer>
                            </NotAvailableContainer>
                            <h4 >Last Task</h4>
                            {
                                singleDBMachine?.lastTask ? <>
                                    <TimelineItem openDefault margin data={singleDBMachine?.lastTask} />
                                    <Button onClick={() => setTasksModal({ open: true })} maxWidth orange >View Tasks Timeline</Button>
                                </> : <Flex width='100%' center justcenter mt='20'><P>There were no tasks for this machine yet</P></Flex>
                            }

                        </DBMachineRightSide>
                    </Flex>
                </PageWrapper>
            </Animation>
            {imagesModal.open && (
                <ImagesSlider images={imagesModal.images} setImagesModal={setImagesModal} />
            )}
            {
                tasksModal.open && <Modal black>
                    <TasksModal setTasksModal={setTasksModal} id={id} />
                </Modal>
            }
        </>
    )
}
