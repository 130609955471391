import React from 'react'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import { BlackCircle, Flex, Collapsable } from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { ITaskTemplateState, ITaskTemplateWComponent } from 'types/task-templates.interface'

export const TaskNonCollapseItem = ({ data, setState }) => {
  const handleRemove = () => {
    setState((prev: ITaskTemplateState) => {
      const copy = { ...prev }
      copy.workerComponents = copy.workerComponents.filter((c: ITaskTemplateWComponent) => {
        return c.id !== data.id
      })
      return copy
    })
  }

  return (
    <Collapsable expanded={false}>
      <Flex mb='20'>
        <CollapseItemText expanded={false}>{data && data.name}</CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
    </Collapsable>
  )
}


interface TaskNonCollapseItem {
  data: ITaskTemplateWComponent
  setState: React.Dispatch<React.SetStateAction<ITaskTemplateState>>
}