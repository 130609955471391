import React, { SetStateAction } from 'react'
import { Input } from 'components/Input/Input'
import { Animation, Button, Flex, TabFooter } from 'styling/GlobalStyles'
import { useNavigate } from 'react-router-dom'
import { ISelectValue } from 'constants/globalTypes'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { standardFieldValidation, validateSelect } from 'utils/validationUtils'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IIssueTriggerState } from 'types/issue-triggers.interface'

export const TriggersBasicInfo = ({
  state,
  setState,
  handleSubmit,
  invalidFields,
  setInvalidFields,
  triggerSimpleRequest,
}: ITriggersBasicInfo) => {
  const navigate = useNavigate()

  useFocusFirstInput()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    standardFieldValidation(e, setInvalidFields)
    const { name, value } = e.target as HTMLInputElement
    setState({ ...state, [name]: value })
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    const obj = { id: value.value, name: value.label }
    validateSelect(value, name, setInvalidFields)
    if (name === 'issue') {
      if (value.value) {
        triggerSimpleRequest(value.value)
      }
      setState((prev) => {
        const copy = { ...prev }
        copy.companies = []
        copy.issue = obj
        return copy
      })
      return
    }

    setState({ ...state, [name]: obj })
  }

  const parseIssues = (data: any) => {
    return data ? data.data.map((item: any) => ({ label: item.name, value: item.id })) : []
  }

  const parseTasks = (data: any) => {
    return data ? data.data.map((item: any) => ({ label: item.name, value: item.id })) : []
  }

  return (
    <Animation>
      <Flex column width='30%'>
        <Input
          type='text'
          name='name'
          labelText='Trigger title'
          value={state?.name}
          onChange={handleChange}
          placeholder='Enter Trigger title'
          invalid={invalidFields.includes('name') ? 'true' : ''}
        />
        <HonestSelectAsyncInput
          defaultValue={state?.issue?.name}
          link='issues'
          labelText='Issue Event'
          onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
          name='issue'
          placeholder='Search issues'
          parserFunction={parseIssues}
          invalid={invalidFields.includes('issue') ? 'true' : ''}
        />
        <HonestSelectAsyncInput
          defaultValue={state?.taskTemplate?.name}
          link='task-template/overviews'
          labelText='Task Event'
          onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
          name='taskTemplate'
          placeholder='Search task templates'
          parserFunction={parseTasks}
          invalid={invalidFields.includes('taskTemplate') ? 'true' : ''}
        />
      </Flex>
      <TabFooter>
        <Flex gap='1rem'>
          <Button type='button' onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type='button' onClick={handleSubmit} orange>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}



interface ITriggersBasicInfo {
  state: IIssueTriggerState
  setState: React.Dispatch<SetStateAction<IIssueTriggerState>>
  triggerSimpleRequest: (value: number) => void
  invalidFields: string[]
  setInvalidFields: React.Dispatch<SetStateAction<string[]>>
  handleSubmit: () => void
}
