import React, { useState, useEffect, useRef } from 'react'
import Chevron from '../../assets/icons/Chevron'
import './HonestSelect.css'
import { CloseIcon } from '../../assets/icons/CloseIcon'
import { InvalidText, Placeholder } from 'styling/GlobalStyles'
import { driverLicense } from 'pages/Employees/constants'
import { isEmptyArray } from 'utils/helpers'
import { toast } from 'react-toastify'
import { ISelectValue } from 'constants/globalTypes'
import { Anchored, useOverlayContext } from 'OverlayContext'

export const HonestSelect = ({
  options,
  onChange,
  width = 'auto',
  reset,
  defaultValue,
  multi,
  name,
  placeholder,
  api,
  invalid,
  editingMultiNone,
  optionsForDefault,
  noX,
  isNone,
  menuTop,
  errorWhenEmpty
}: IHonestSelect) => {
  const [openOptions, setOpenOptions] = useState(false)
  const [blured, setBlured] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    defaultValue && typeof defaultValue == 'string' && defaultValue.length > 0
      ? optionsForDefault
        ? optionsForDefault.find((item) => item.value.toLowerCase() === defaultValue.toLowerCase())
        : options.find((item) => item.value.toLowerCase() === defaultValue.toLowerCase())
      : typeof defaultValue == 'number'
        ? options?.find((item) => item.value === defaultValue)
        : null,
  )
  const [selectedMulti, setSelectedMulti] = useState(
    multi && defaultValue
      ? defaultValue.map((item) => ({ label: item.toUpperCase(), value: item.toLowerCase() }))
      : [],
  )
  const [editableOptions, setEditableOptions] = useState(options)
  // check none option for multi
  const [none, setNone] = useState(editingMultiNone ? editingMultiNone : isNone ?? false)

  const filterItemString = (list) =>
    list.find((item) => item.value.toLowerCase() === defaultValue.toLowerCase())
  const filterItemNumber = (list) => list?.find((item) => item.value === defaultValue)
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { overlay } = useOverlayContext();

  useEffect(() => {
    if (multi && defaultValue) {
      setEditableOptions(options.filter((x) => !defaultValue.includes(x.value)))
    }
  }, [])

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === 'enter') {
      event.preventDefault()
      event.target.parentNode.nextSibling.children[1].focus()
    }
  }
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      setSelectedOption(null)
      defaultValue = null
      return
    } else {
      didMount.current = true
    }
  }, [reset])

  useEffect(() => {
    if (
      selectedOption &&
      !optionsForDefault &&
      !options.some((option) => option.value == selectedOption.value)
    ) {
      setSelectedOption(null)
    }
    const filterList = (list) => list.filter((x) => !defaultValue.includes(x.value))
    setEditableOptions(
      multi && defaultValue ? filterList(optionsForDefault ? optionsForDefault : options) : options,
    )
  }, [options])

  useEffect(() => {
    if (api) {
      if (defaultValue && typeof defaultValue == 'string') {
        setSelectedOption(filterItemString(optionsForDefault ? optionsForDefault : options))
      } else {
        setSelectedOption(
          filterItemNumber(!isEmptyArray(optionsForDefault) ? optionsForDefault : options),
        )
      }
    }
  }, [options])

  useEffect(() => {
    if (defaultValue && typeof defaultValue == 'string') {
      setSelectedOption(filterItemString(optionsForDefault ? optionsForDefault : options))
    } else if (defaultValue && typeof defaultValue == 'number') {
      setSelectedOption(
        filterItemNumber(!isEmptyArray(optionsForDefault) ? optionsForDefault : options),
      )
    }
    if (multi && defaultValue && selectedMulti.length == 0) {
      setSelectedMulti(
        defaultValue.map((item) => ({ label: item.toUpperCase(), value: item.toLowerCase() })),
      )
    }
  }, [defaultValue])

  const handleChange = (item) => {
    if (multi) {
      defaultValue = null
      const isNone = item.label === 'None'

      if (isNone) {
        setEditableOptions(() => {
          return driverLicense
        })
        setSelectedMulti([])
        setNone(true)
        onChange({ label: 'multiNone', value: null }, name)
        return
      }
      setEditableOptions((prev) => prev.filter((it) => it.value !== item.value))

      setSelectedMulti((prev) => {
        const isFound = prev.some((element) => element.value === item.value)
        setNone(false)
        const filtered = prev.filter((i) => i.value !== item.value)
        const noLicense = item.value === null
        const forReturn = isFound ? filtered : noLicense ? null : isNone ? [] : [...prev, item]

        if ((forReturn.length > 0 || isNone) && onChange && multi && forReturn) {
          onChange(forReturn, name)
        }
        return forReturn
      })
    } else {
      setSelectedOption(item)
      onChange && item && item !== null ? onChange(item, name) : null
    }
  }

  const handleRemove = (item) => {
    setEditableOptions((prev) => [...prev, item])
    setSelectedMulti((prev) => prev.filter((it) => it !== item))
    defaultValue = null
  }

  const shadow =
    selectedOption || selectedMulti.length > 0 || none ? '0px 0px 30px rgba(0, 0, 0, 0.1)' : 'none'
  const border =
    blured && invalid
      ? '1px solid red'
      : selectedOption || selectedMulti.length > 0 || none
        ? 'none'
        : '1px solid #c3c0cc'
  const background = selectedOption || selectedMulti.length > 0 || none ? 'white' : 'none'

  const clearSelect = (e) => {
    e.stopPropagation()
    if (multi) {
      setSelectedMulti([])
      setEditableOptions(driverLicense)
      setNone(false)
      setOpenOptions(false)
      defaultValue = null
      onChange([], name)
      return
    }
    onChange({ value: null, label: '' }, name)
    setSelectedOption(null)
    setOpenOptions(false)
    defaultValue = null
  }

  const handleOpen = () => {
    if (errorWhenEmpty && !options.length) {
      toast.error(errorWhenEmpty)
      return
    }
    setOpenOptions((prev) => !prev)
    overlay.style.pointerEvents = 'all'
  }

  return (
    <div
      tabIndex={0}
      onBlur={() => {
        setBlured(true)
        setOpenOptions(false)
        overlay.style.pointerEvents = 'none'
      }}
      onClick={handleOpen}
      style={{
        width,
        minWidth: width,
        maxWidth: width,
        boxShadow: shadow,
        border,
        background,
      }}
      className='honest-select-container'
      ref={anchorRef}
      onKeyDown={handleEnter}
    >
      {multi ? (
        selectedMulti && selectedMulti.length > 0 ? (
          <>
            <div className='multi-container'>
              {selectedMulti.map((item, key) => {
                return (
                  <div key={key} className='multi-single'>
                    {item.label}
                    <div onClick={() => handleRemove(item)} className='close-container'>
                      <CloseIcon />
                    </div>
                  </div>
                )
              })}
            </div>
          </>

        ) : (
          <p>{none ? 'None' : <Placeholder>Select</Placeholder>}</p>
        )
      ) : (
        <p>
          {selectedOption ? (
            selectedOption.label
          ) : placeholder ? (
            <Placeholder>{placeholder}</Placeholder>
          ) : (
            <Placeholder>Select</Placeholder>
          )}
        </p>
      )}
      {name === 'rating' || name === 'priority' || name === 'parameter' || name === 'unit' ? (
        <Chevron />
      ) : (
        <div className='icons-container' style={noX && { justifyContent: 'flex-end' }}>
          <Chevron />
          {!noX && (
            <span onClick={(e) => clearSelect(e)}>
              <CloseIcon />
            </span>
          )}
        </div>
      )}

      {openOptions && (
        <Anchored to={anchorRef} options={editableOptions.length} menuTopAndOptions={menuTop ? options.length : null}>
          <div className='honest-options-container' >
            {editableOptions.map((item, key) => {
              return (
                <div onMouseDown={() => handleChange(item)} key={key} className='honest-option'>
                  {item.label}
                </div>
              )
            })}
          </div>
        </Anchored>
      )}
      <article>{invalid && blured && <InvalidText>This field is required</InvalidText>}</article>
    </div>
  )
}


interface IHonestSelect {
  onChange: (value: ISelectValue | any[], name: string | number) => void
  options: any[]
  reset?: any
  width?: string
  defaultValue?: any
  multi?: boolean
  name?: number | string
  placeholder?: string
  api?: boolean
  invalid?: boolean
  editingMultiNone?: boolean
  optionsForDefault?: any[]
  noX?: boolean
  isNone?: boolean
  menuTop?: boolean
  errorWhenEmpty?: string
}