import { TasksTabs } from 'pages/TaskTemplates/DragComponents/Tasks.styled'
import styled from 'styled-components'

export const DropDownTab = styled(TasksTabs)`
  position: relative;
  @media (max-width: 1300px) {
    margin-bottom: 1rem;
  }
`

interface IDropDownOptionTabs {
  showDropDown: boolean
}

export const DropDownOptionTabs = styled.div<IDropDownOptionTabs>`
  position: absolute;
  top: 110%;
  left: 50%;
  gap: 1rem;
  display: flex;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  padding: 10px;
  box-shadow: 8.3px 1.2rem 2.8rem rgba(7, 6, 61, 0.05);
  transition: 0.2s ease;
  transform-origin: top;
  ${(props) =>
    props.showDropDown
      ? 'opacity:1; { transform: scaleY(1)  translateX(-50%)}'
      : 'opacity:0; { transform: scaleY(0)  translateX(-50%)}'}

  @media (max-width: 1300px) {
    width: 100%;
  }
`
