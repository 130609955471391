import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { INameId } from 'constants/globalTypes'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import {
  IWorkflowsSingleResponse,
  IWorkflowTemplateForCompanyResponse,
} from 'types/api/workflowsAPi.interface'
import { IWorkflow, IWorkflowsSliceState } from 'types/workflows.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'

const initialState: IWorkflowsSliceState = {
  isLoading: false,
  workflows: [],
  totalCount: 0,
  totalPages: 0,
  singleWorkflow: null,
  noWorkflows: false,
  workflowTemplateForCompany: null,
}

export const getWorkflows = createAsyncThunk(
  'workflows/getWorkflows',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp: AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get(
        '/workflow/template',
        { params },
      )
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleWorkflow = createAsyncThunk(
  'workflows/getSingleWorkflow',
  async (id: number, thunkAPI) => {
    try {
      const resp: AxiosResponse<IWorkflowsSingleResponse> = await baseUrl.get(
        '/workflow/template/' + id,
      )
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleWorkflowTemplateForCompany = createAsyncThunk(
  'workflows/getSingleWorkflow',
  async (id: string, thunkAPI) => {
    try {
      const resp: AxiosResponse<IWorkflowTemplateForCompanyResponse> = await baseUrl.get(
        '/workflow/template/company/' + id,
      )
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addWorkflow = createAsyncThunk(
  'workflows/addWorkflow',
  async (workflow: IWorkflow, thunkAPI) => {
    try {
      const response = await baseUrl.post('/workflow/template', workflow)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const editWorkflow = createAsyncThunk(
  'workflows/editWorkflow',
  async (workflow: IWorkflow, thunkAPI) => {
    try {
      const response = await baseUrl.patch('/workflow/template/' + workflow.id, workflow)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteWorkflow = createAsyncThunk(
  'workflows/deleteWorkflow',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('workflow/template/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    clearWorkflowsList: (state) => {
      state.workflows = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearSingleWorkflow: (state) => {
      state.singleWorkflow = null
    },
  },
  extraReducers: {
    [getWorkflows.pending.type]: (state) => {
      state.isLoading = true
    },
    [getWorkflows.fulfilled.type]: (
      state,
      { payload }: { payload: IPaginatedResponse<IWorkflowsSingleResponse> },
    ) => {
      state.isLoading = false
      state.workflows = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noWorkflows = payload.data.length < 1
    },
    [getWorkflows.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleWorkflow.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleWorkflow.fulfilled.type]: (
      state,
      { payload }: { payload: IWorkflowsSingleResponse },
    ) => {
      const obj = {
        id: payload.id,
        name: payload.name,
        items: payload.items.map((i) => {
          const ob: any = {}
          ob.taskTemplateId = i.taskTemplate.id
          ob.level = i.level
          ob.index = i.index
          ob.name = i.taskTemplate.name
          ob.parentItems = i.data ? i.data.parentItems : []
          return ob
        }),
        companies: payload.companies.map((c) => ({ ...c.company })),
      }
      state.singleWorkflow = obj
      state.isLoading = false
    },
    [getSingleWorkflow.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addWorkflow.pending.type]: (state) => {
      state.isLoading = true
    },
    [addWorkflow.fulfilled.type]: (state, { payload }: { payload }) => {
      state.isLoading = false
      if (state.workflows.length < 30) {
        state.workflows.push({ id: payload.id, name: payload.name })
      }
      state.noWorkflows = false
      toast.success('Workflow successfully added.')
    },
    [addWorkflow.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editWorkflow.pending.type]: (state) => {
      state.isLoading = true
    },
    [editWorkflow.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.workflows.length < 30) {
        state.workflows.push({ id: payload.id, name: payload.name })
      }
      state.noWorkflows = false
      toast.success('Workflow successfully updated.')
    },
    [editWorkflow.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteWorkflow.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteWorkflow.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.workflows = state.workflows.filter((w) => w.id !== payload)
      state.noWorkflows = state.workflows.length < 1
      toast.success('Workflow successfully deleted.')
    },
    [deleteWorkflow.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleWorkflowTemplateForCompany.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleWorkflowTemplateForCompany.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.workflowTemplateForCompany = payload
    },
    [getSingleWorkflowTemplateForCompany.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearWorkflowsList, clearSingleWorkflow } = workflowsSlice.actions
export default workflowsSlice.reducer
