/*eslint-disable*/
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { ISelectValue } from 'constants/globalTypes'
import { generateWorkflow } from 'features/tasks/tasksSlice'
import { criteria } from 'pages/TaskTemplates/taskData'
import { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { Box, Button, Flex, H2, P, Separator, Title } from 'styling/GlobalStyles'
import { IAddTaskState } from 'types/tasks.interface'
import { formatDateForDisplay, getTimeFromDate } from 'utils/helpers'

export const WorkflowSection = ({
    handleChange,
    config,
    locations,
    setConfig,
    configRef,
    departmentId,
    parseToolComponents
}: IWorkflowSection) => {
    const dispatch = useDispatch<AppDispatch>()
    const { generatedWorkflow } = useSelector((state: RootState) => state.tasks)
    const handleLocation = (v: ISelectValue, name: string, itemId?: number) => {
        setConfig((prev) => {
            const copy = { ...prev }
            const index = config.items.findIndex((i) => i.workflowTemplateItemId == itemId)
            config.items[index].location = v
            return copy
        })
    }

    const handleValue = (e: ChangeEvent<HTMLInputElement>, itemId: number, componentId: number) => {
        setConfig((prev) => {
            const copy = { ...prev }
            const itemIndex = config.items.findIndex((i) => i.workflowTemplateItemId == itemId)
            const componentIndex = config.items[itemIndex].components.findIndex(
                (c) => c.id == componentId,
            )
            config.items[itemIndex].components[componentIndex].value = e.target.valueAsNumber

            return copy
        })
    }

    const handleGenerateWorkflow = async () => {
        // const taskRequiresMachine = inputComponents.taskTemplate.toolTypeComponents.some(c => c.name == 'Machine')
        // const taskRequiresItem = inputComponents.taskTemplate.toolTypeComponents.some(c => c.name == 'Item')
        // if (!config.location || !config.task || !config.criteria || config.components.some(c => !c.value || (taskRequiresMachine && !config.machine) || (taskRequiresItem && (!config.item || !config.item.amount)))) {
        //     toast.warn('Please fill all fields')
        //     return
        // }
        await dispatch(generateWorkflow({ config, departmentId }))
        configRef.current.scrollTop = configRef.current.scrollHeight
    }


    const handleToolTypeComponents = (value: ISelectValue, name: string, itemId: number, taskTemplateToolTypeComponentId: number) => {
        setConfig(prev => {
            const copy = { ...prev }
            const index = prev.items.findIndex(i => i.id == itemId)
            copy.items[index][name] = { ...value, taskTemplateToolTypeComponentId }
            return copy
        })
    }

    const handleAmount = (e: ChangeEvent<HTMLInputElement>, itemId: number) => {
        const { valueAsNumber } = e.target
        setConfig(prev => {
            const copy = { ...prev }
            const index = copy.items.findIndex(i => i.id == itemId)
            copy.items[index].item.amount = valueAsNumber
            return copy
        })
    }

    return (
        <>
            <HonestSelectInput
                labelText='Criteria'
                options={criteria}
                onChange={handleChange}
                name='criteria'
                defaultValue={config?.criteria}
            />
            {config?.items?.map((i) => (
                <>
                    <H2 mb='10px'>{i.name}</H2>
                    <HonestSelectInput
                        labelText='Location'
                        options={locations}
                        onChange={(v: ISelectValue) =>
                            handleLocation(v, 'component-location', i.workflowTemplateItemId)
                        }
                        name='component-location'
                        defaultValue={i.location}
                    />

                    {i.components
                        .filter((c) => c.componentType == 1)
                        .map((c) => (
                            <Input
                                placeholder='Enter value'
                                type='number'
                                labelText={c.name}
                                onChange={(e) => handleValue(e, i.workflowTemplateItemId, c.id)}
                                name={c.name}
                                value={c.value}
                            />
                        ))}
                    {i?.machine && <HonestSelectAsyncInput
                        name='machine'
                        labelText='Machinery'
                        onChange={(v, n) => handleToolTypeComponents(v, n, i.id, i.machine.taskTemplateToolTypeComponentId)}
                        link='machines'
                        parserFunction={parseToolComponents}
                    />}
                    {
                        i?.item && <Flex gap='1rem' >
                            <HonestSelectAsyncInput
                                name='item'
                                labelText='Item'
                                onChange={(v, n) => handleToolTypeComponents(v, n, i.id, i.item.taskTemplateToolTypeComponentId)}
                                link='inventory-items'
                                parserFunction={parseToolComponents}
                            />
                            <Input width='25%' labelText='Amount' onChange={e => handleAmount(e, i.id)} placeholder='5' type='number' value={i.item?.amount || 0} />
                        </Flex>
                    }
                </>
            ))}
            <Button orange onClick={handleGenerateWorkflow}>
                Generate
            </Button>
            {generatedWorkflow && (
                <>
                    <Separator />
                    <Flex column gap='1rem'>
                        <Title pad='0'>Generated Workflow Preview</Title>
                        <TextDisplayCard
                            labelText='Estimated cost'
                            title={generatedWorkflow.estimatedCost.toFixed(2)}
                        />
                        <Flex gap='1rem'>
                            <TextDisplayCard
                                width='100%'
                                labelText='Start'
                                title={
                                    formatDateForDisplay(generatedWorkflow.estimatedStartDate) +
                                    ' ' +
                                    getTimeFromDate(generatedWorkflow.estimatedStartDate)
                                }
                            />
                            <TextDisplayCard
                                width='100%'
                                labelText='End'
                                title={
                                    formatDateForDisplay(generatedWorkflow.estimatedStartDate) +
                                    ' ' +
                                    getTimeFromDate(generatedWorkflow.estimatedStartDate)
                                }
                            />
                        </Flex>
                        <H2 mb='0.5'>Tasks</H2>
                        {generatedWorkflow.items.map((i) => (
                            <Box>
                                <Flex column>
                                    <Flex between mb='10'>
                                        <h4>{i.taskTemplate.name}</h4>
                                        <h4>
                                            {getTimeFromDate(i.task.estimatedStartDate) +
                                                ' - ' +
                                                getTimeFromDate(i.task.estimatedEndDate)}
                                        </h4>
                                    </Flex>
                                    {i.task.userTasks.map((ut) => (
                                        <Flex key={ut.id} column>
                                            <Flex between>
                                                <P>{ut.fullName}</P>
                                                <P>
                                                    {getTimeFromDate(ut.estimatedStartDate) +
                                                        ' - ' +
                                                        getTimeFromDate(ut.estimatedEndDate)}
                                                </P>
                                            </Flex>
                                            {ut.executionPlans.length ? ut.executionPlans.map(ep => <P style={{ color: 'grey' }} key={ep.id} >{ep.machineName ?? 'No Machine'}</P>) : <P style={{ color: 'grey' }} >No Machine</P>}
                                        </Flex>
                                    ))}
                                </Flex>
                            </Box>
                        ))}
                    </Flex>
                </>
            )}
        </>
    )
}

interface IWorkflowSection {
    config: IAddTaskState
    handleChange: (value: ISelectValue, name: string) => void
    locations: ISelectValue[]
    setConfig: React.Dispatch<React.SetStateAction<IAddTaskState>>
    configRef: React.MutableRefObject<any>
    departmentId: number
    parseToolComponents: (r: any) => any

}
