import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import {
  IGetAllLogicsSingleResponse,
  IGetAllTaskTemplatesSingleResponse,
  ILogicPostRequest,
  ITaskInputComponentsResponse,
  ITaskTemplatesPatchRequest,
  ITaskTemplatesPostRequest,
  ITaskTemplatesSinleResponse,
  IWorkUnitAmountsPostRequest,
} from 'types/api/tasksTemplatesApi.interface'
import {
  ITaskTemplatesSlice,
  ITaskTemplateState,
  ITaskTemplateStateComponent,
  ITaskTemplateWComponent,
  ITaskTemplateWorkUnitAmount,
  IToolTypeComponent,
} from 'types/task-templates.interface'
import { baseUrl } from 'utils/axios'
import { dataURItoBlob, handleErrors, formatParams } from 'utils/helpers'
import { v4 } from 'uuid'

const initialState: ITaskTemplatesSlice = {
  isLoading: false,
  tasksLogics: [],
  createdId: null,
  singleTaskTemplate: null,
  allTaskTemplates: [],
  totalPages: 0,
  totalCount: 0,
  noTaskTemplates: false,
  inputComponents: null,
}

export const getTasksTemplateLogics = createAsyncThunk(
  'taskTemplates/getTasksLogics',
  async (ai: boolean, thunkAPI) => {
    try {
      const resp: AxiosResponse<IGetAllLogicsSingleResponse[]> = await baseUrl.get(
        ai ? 'task-template/logics/ai' : 'task-template/logics',
      )
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getInputComponents = createAsyncThunk(
  'taskTemplates/getInputComponents',
  async (id: string, thunkAPI) => {
    try {
      const resp: AxiosResponse<ITaskInputComponentsResponse> = await baseUrl.get(
        'task-template/' + id + '/input-components',
      )
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getToolComponents = createAsyncThunk(
  'taskTemplates/getToolComponents',
  async (_, thunkAPI) => {
    try {
      const resp = await baseUrl.get('task-template/logics')
      const newObject = resp.data.map((item) => {
        item.input = item.input.map((i) => ({ ...i, id: v4() }))
        item.output = item.output.map((o) => ({ ...o, id: v4() }))
        return item
      })
      return newObject
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addTaskTemplate = createAsyncThunk(
  'taskTemplates/addTask',
  async (task: ITaskTemplateState, thunkAPI) => {
    try {
      const { image } = task
      const forSubmit: ITaskTemplatesPostRequest = {
        operationTypeParameterValues: task.operationTypeParameterValues.map((p) => {
          if (p.parameterId == 1) {
            return p
          }
          return { ...p, desiredValue: p.minimumValue }
        }),
        toolTypeComponents: task.toolTypeComponents.map((t: IToolTypeComponent) => ({
          toolTypeComponentId: t.component.value,
          name: t.name,
          unitId: t.component.label == 'Item' ? t.unit.value : null,
        })),
        workerComponentFieldIds: task.workerComponents.map((c: ITaskTemplateWComponent) => c.id),
        name: task.name,
        operationTypeId: task.operationTypeId,
        components: task.components,
        shouldUseLocationTracking: task.shouldUseLocationTracking,
      }

      const resp = await baseUrl.post('task-template', forSubmit)
      if (image) {
        const blobedImage = dataURItoBlob(image)
        try {
          await axios.put(resp.data.imagePresignedUploadUrl, blobedImage)
          return resp.data
        } catch (error) {
          console.log(error)
          return thunkAPI.rejectWithValue(error)
        }
      }
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const duplicateTaskTemplate = createAsyncThunk(
  'taskTemplates/duplicateTask',
  async (nameId: { name: string; taskTemplateId: number }, thunkAPI) => {
    try {
      const resp = await baseUrl.post('task-template/duplicate', nameId)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getTaskTemplates = createAsyncThunk(
  'taskTemplates/getTasks',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp: AxiosResponse<IPaginatedResponse<IGetAllTaskTemplatesSingleResponse>> =
        await baseUrl.get('task-template/overviews', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const editTaskTemplate = createAsyncThunk(
  'taskTemplates/editTask',
  async (task: ITaskTemplateState, thunkAPI) => {
    // § Proveriti da li task koji stize je isti kao interface EDIT: Nije, komponente
    try {
      const { id, image } = task
      const forSubmit: ITaskTemplatesPatchRequest = {
        operationTypeParameterValues: task.operationTypeParameterValues.map((p) => {
          if (p.parameterId == 1) {
            return p
          }
          return { ...p, desiredValue: p.minimumValue }
        }),
        toolTypeComponents: task.toolTypeComponents.map((t: IToolTypeComponent) => ({
          toolTypeComponentId: t.component.value,
          name: t.name,
          unitId: t.component.label == 'Item' ? t.unit.value : null,
        })),
        workerComponentFieldIds: task.workerComponents.map((c: ITaskTemplateWComponent) => c.id),
        name: task.name,
        operationTypeId: task.operationTypeId,
        shouldUseLocationTracking: task.shouldUseLocationTracking,
        components: task.components.map((c) => ({
          component: c.component,
          componentType: c.componentType,
          name: c.name,
        })),
      }
      const resp = await baseUrl.patch('task-template/' + id, forSubmit)
      if (image) {
        const blobedImage = dataURItoBlob(image)
        try {
          await axios.put(resp.data.imagePresignedUploadUrl, blobedImage)
          return resp.data
        } catch (error) {
          console.log(error)
          return thunkAPI.rejectWithValue(error)
        }
      }
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addLogic = createAsyncThunk(
  'taskTemplates/addLogic',
  async (
    logic: { id: string; components: ITaskTemplateStateComponent[]; functionType: number }, // ispitati
    thunkAPI,
  ) => {
    try {
      const { id, components, functionType } = logic
      const forDispatch: ILogicPostRequest = {
        componentLogics: components.map((c) => ({
          functionType,
          calculatedTaskComponentId: c.id,
          functionId: c.functionId,
          functionResultIndex: c.functionResultIndex,
          inputParameters: c.inputParameters,
        })),
      }
      const resp = await baseUrl.post('task-template/' + id + '/logic', forDispatch)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addWorkUnitAmounts = createAsyncThunk(
  'taskTemplates/addWorkUnitAmounts',
  async (workUnitsAmounts: IWorkUnitAmountsPostRequest, thunkAPI) => {
    try {
      const resp = await baseUrl.post('task-template/work-unit-amount-per-ranges', workUnitsAmounts)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleTaskTemplate = createAsyncThunk(
  'taskTemplates/geSingleTask',
  async (id: number, thunkAPI) => {
    try {
      const resp: AxiosResponse<ITaskTemplatesSinleResponse> = await baseUrl.get(
        'task-template/' + id,
      )
      const opType = resp.data.operationType
      const param = opType.operationTypeParameterValues
      const taskObejct: ITaskTemplateState = {
        functionType: resp.data.components.find((c) => c.functionType)?.functionType,
        image: null,
        imageRaw: null,
        logic: [],
        name: resp.data.name,
        operationTypeId: opType.operationTypeId,
        operationTypeParameterValues: param.map((item) => ({
          operationTypeParameterId: item.operationTypeParameterId,
          desiredValue: item.desiredValue,
          minimumValue: item.minimumValue,
          parameterId: item.parameterId,
        })),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // TODO
        components: resp.data.components,
        workerComponents: resp.data.workerComponents.map((wc) => ({
          id: wc.field.id,
          name: wc.field.name,
        })),
        workUnitAmounts: resp.data.workUnitAmounts.map(
          (item: ITaskTemplateWorkUnitAmount, id: number) => ({
            ...item,
            id,
          }),
        ),
        selectedOperation: {
          id: opType.operationId,
          name: opType.operationTypeName,
          types: [
            {
              id: opType.operationTypeId,
              name: opType.operationTypeName,
              parameters: param.map((item) => ({
                id: item.operationTypeParameterId,
                name: item.parameterName,
                parameterId: item.parameterId,
                unit: item.unitName,
                unitId: item.unitId,
              })),
            },
          ],
        },
        toolTypeComponents: resp.data.toolTypeComponents.map((i) => ({
          component: {
            label: i.toolTypeComponent.name,
            value: i.toolTypeComponent.id,
          },
          name: i.name,
          unit: i.unit
            ? {
                label: i.unit.name,
                value: i.unit.id,
              }
            : null,
        })),
        shouldUseLocationTracking: resp.data.shouldUseLocationTracking,
      }

      return taskObejct
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteTaskTemplate = createAsyncThunk(
  'taskTemplates/deleteTask',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('task-template/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSimpleTaskTemplates = createAsyncThunk(
  'taskTemplates/getSimpleTasks',
  async (_, thunkAPI) => {
    try {
      const resp: AxiosResponse<IPaginatedResponse<IGetAllTaskTemplatesSingleResponse>> =
        await baseUrl.get('/task-templates/simple', thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
const taskTemplatesSlice = createSlice({
  name: 'task-templates',
  initialState,
  reducers: {
    clearTaskTemplate: (state) => {
      state.createdId = null
      state.singleTaskTemplate = null
      state.tasksLogics = []
    },
    clearInputComponents: (state) => {
      state.inputComponents = null
    },
    clearTaskTemplatesList: (state) => {
      state.allTaskTemplates = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getTasksTemplateLogics.pending.type]: (state) => {
      state.isLoading = true
    },
    [getTasksTemplateLogics.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.tasksLogics = payload
      state.noTaskTemplates = state.allTaskTemplates.length < 1
    },
    [getTasksTemplateLogics.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addTaskTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [addTaskTemplate.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.createdId = payload.id
      state.noTaskTemplates = false
      toast.success('Task template successfully created')
    },
    [addTaskTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editTaskTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [editTaskTemplate.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTaskTemplate.components = payload.components
      toast.success('Task template updated successfully')
    },
    [editTaskTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addLogic.pending.type]: (state) => {
      state.isLoading = true
    },
    [addLogic.fulfilled.type]: (state) => {
      state.isLoading = false
      toast.success('Logic successfully saved')
    },
    [addLogic.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [duplicateTaskTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [duplicateTaskTemplate.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      toast.success('Task template successfully duplicated')
      if (state.allTaskTemplates.length < 5) {
        state.allTaskTemplates.push(payload)
      }
    },
    [duplicateTaskTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addWorkUnitAmounts.pending.type]: (state) => {
      state.isLoading = true
    },

    [addWorkUnitAmounts.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTaskTemplate.workUnitAmounts = payload
      toast.success('Work unit amounts successfully saved')
    },
    [addWorkUnitAmounts.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleTaskTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleTaskTemplate.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTaskTemplate = payload
    },
    [getSingleTaskTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getTaskTemplates.pending.type]: (state) => {
      state.isLoading = true
    },
    [getTaskTemplates.fulfilled.type]: (
      state: ITaskTemplatesSlice,
      { payload }: { payload: IPaginatedResponse<IGetAllTaskTemplatesSingleResponse> },
    ) => {
      state.isLoading = false
      state.allTaskTemplates = payload.data
      state.noTaskTemplates = payload.data.length < 1
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
    },
    [getTaskTemplates.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteTaskTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteTaskTemplate.fulfilled.type]: (state: ITaskTemplatesSlice, { payload }) => {
      state.isLoading = false
      toast.success('Task template deleted')
      state.allTaskTemplates = state.allTaskTemplates.filter((item) => item.id !== payload)
    },
    [deleteTaskTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSimpleTaskTemplates.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSimpleTaskTemplates.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.allTaskTemplates = payload
    },
    [getSimpleTaskTemplates.rejected.type]: (state) => {
      state.isLoading = false
    },
    [getInputComponents.pending.type]: (state) => {
      state.isLoading = true
    },
    [getInputComponents.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.inputComponents = payload
    },
    [getInputComponents.rejected.type]: (state) => {
      state.isLoading = false
    },
  },
})

export const { clearTaskTemplate, clearTaskTemplatesList, clearInputComponents } =
  taskTemplatesSlice.actions
export default taskTemplatesSlice.reducer
